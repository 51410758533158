import React from 'react';
import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';

export default function CircleStatusIndicator (props) {
  const {
    color,
  } = props;

  return (
    <CircleIcon className='help-icon' style={{ fontSize: 16, color }} />
  );
}

CircleStatusIndicator.propTypes = {
  color: PropTypes.string,
};
