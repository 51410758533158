import './DeviceScreen.scss';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import RefetchIcon from '@mui/icons-material/ReplayOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import PointIcon from '@mui/icons-material/FiberManualRecord';
import NextIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PreviousIcon from '@mui/icons-material/ArrowBackIosOutlined';
import toast from 'src/utils/toast';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { fetchClient } from 'src/module/authentication/action';
import { getAuthenticatedUser, getPluralWorkTicketLabel } from 'src/module/authentication/selector';
import { getDeviceByTag, getDeviceStatus, getDeviceSettings, getDeviceConfigFields, getDeviceActions, getIsDeviceInCommFail, getSiblingsInfo } from 'src/module/device/selector';
import { fetchDeviceConfigFields, fetchDeviceStatus, fetchDeviceAlarms, fetchDeviceSettings, fetchDevicesWithAlarms, fetchDeviceActions, fetchDevicesActiveNotes, fetchDevicesArchivedNotes, fetchDevicesActiveWorkTickets, fetchDevicesArchivedWorkTickets, fetchStatistics, fetchDeviceSensorHistory, fetchDevicesAuditTrail, fetchDevicesActiveAlarms, fetchDevicesArchivedAlarms, fetchHiddenCircuits, fetchSiblingsInfo } from 'src/module/device/action';
import { getTemperatureUnit } from 'src/module/authentication/selector';
import BuildIcon from '@mui/icons-material/Build';
import { getSettingsModal } from 'src/module/device/selector';
import { setSettingsModalClose, setSettingsModalOpen } from 'src/module/device/action';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import variables from 'src/style/variable/variables.module.scss';
import NotFoundScreen from 'src/screen/Error/NotFoundScreen';
import DeviceProperties from './DeviceProperties';
import GaugeContainer from './GaugeContainer';
import NotesContent from './NotesContent';
import DeviceStatistics from './DeviceStatistics';
import NotificationsContent from './NotificationsContent';
import AlarmsContent from './AlarmsContent';
import { getIsInactive } from 'src/module/user/selector';
import DeviceSensorGraph from './DeviceSensorGraph';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceScreenActionButtons from './DeviceScreenActionButtons';
import { Modal, Paper } from '@mui/material';
import ChildDevices from './ChildDevices';
import Banner from 'src/component/UI/Banner';
import { deviceTypeConfig } from 'src/utils/deviceTypeConfig';
import AuditContent from './AuditContent';
import TestingReportsContent from './TestingReport/TestingReportsContent';
import { isWhitelabel } from 'src/utils/utils';
import Button from 'src/component/UI/Button';
import { langLookUpValidationError } from 'src/utils/langLookUp';
import DataTable from 'src/component/UI/DataTable';
import { getSettingsDiff } from 'src/module/device/selector';
import PreviewIcon from '@mui/icons-material/Preview';
import SettingsMismatchModal from 'src/component/SettingsMismatchModal';
import ForcedOnContent from './ForcedOnContent';
import SpliceReportsContent from './SpliceReport/SpliceReportContent';
import CircuitFunctionTestReportContent from './CircuitFunctionTestReport/CircuitFunctionTestReportContent';
import InsulationInspectionTestReportContent from './InsulationInspectionTestReport/InsulationInspectionTestReportContent';



export default function DeviceScreen (props) {
  const dispatch = useDispatch();
  const deviceTagFromUrlDirty: string = decodeURIComponent(props.match.params.device_tag);
  const deviceTagFromUrl: string = deviceTagFromUrlDirty === '_' ? null : deviceTagFromUrlDirty;
  const childTagFromUrl: string = decodeURIComponent(props.match.params.child_tag);
  const urlDeviceTag = childTagFromUrl && childTagFromUrl !== 'undefined' ? childTagFromUrl : deviceTagFromUrl;
  const device = useSelector(getDeviceByTag(deviceTagFromUrl, childTagFromUrl));
  const isInactive = useSelector(getIsInactive(window.location.pathname));
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const [loading, setLoading] = useState(true);
  const [previousCircuitTag, setPreviousCircuitTag]: any = useState(null);
  const [nextCircuitTag, setNextCircuitTag]: any = useState(null);

  const deviceId = device?.get('id');
  const deviceConfigFields = useSelector(getDeviceConfigFields(deviceId));
  const deviceActions = useSelector(getDeviceActions(deviceId));
  const isDeviceInCommFail = useSelector(getIsDeviceInCommFail(deviceId));
  const user = useSelector(getAuthenticatedUser);

  const deviceStatus = useSelector(getDeviceStatus(deviceId));
  const deviceSettings = useSelector(getDeviceSettings(deviceId));
  const temperatureUnit = useSelector(getTemperatureUnit);
  const settingsDiff = useSelector(getSettingsDiff(deviceId));
  const designedSettingsModal = useSelector(getSettingsModal(deviceId));
  const allSiblingCircuits = useSelector(getSiblingsInfo(device?.get('parent_id')));
  const designedSettingsModalIsLoading = designedSettingsModal?.get('loading');
  const designedSettingsModalIsOpen = designedSettingsModal?.get('isOpen');
  const designedSettingsModalId = designedSettingsModal?.get('id');


  const deviceFactory = device.get('device_factory', null);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(device.getIn(['updated_at']));
  const statusTempSensors = device?.getIn(['status', 'temperatureSensors']) ?? Map({});
  const numberOfStatusSensors = Object.values(statusTempSensors?.toJS()).filter(x => x != null);
  const numTempSensorsStatus = numberOfStatusSensors.length || 1;
  const numTempSensorsSettings = device?.getIn(['settings', 'temperatureSensors'])?.size || 0;
  const numTemperatureSensors = Math.min(numTempSensorsStatus, numTempSensorsSettings);
  const [isDeviceLive, setIsDeviceLive] = useState(false);
  const [settingsAreUptoDate, setSettingsAreUptoDate] = useState(false);
  const isMobileOrIpad = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const settingsModal = useSelector(getSettingsModal(deviceId));
  const deviceType = device.get('type');
  const [settingsUpdatedAt, setSettingsUpdatedAt] = useState(lastUpdatedAt);
  const [statusUpdatedAt, setStatusUpdatedAt] = useState(lastUpdatedAt);
  const [viewConfigSettingsIssue, setViewConfigSettingsIssue] = useState(false);
  const isCircuit = device?.get('type') === 'circuit';
  const parentIsController = device?.get('parent_type') === 'controller';
  const deviceTag = device?.get('tag');

  const clickDesignedSettingsMismatchIcon = () => {
    dispatch(setSettingsModalOpen(deviceId, SettingsModalTypes['DESIGNED_SETTINGS_MISMATCH_MODAL']));
  };

  const closeDesignedSettingsModal = () => {
    dispatch(setSettingsModalClose(deviceId));
  };


  useEffect(() => {
    dispatch(fetchClient());
    dispatch(setSettingsModalClose(deviceId));
  }, []);

  useEffect(() => {
    if (device?.get('type') === 'circuit' && device?.get('parent_id') && parentIsController) {
      dispatch(fetchSiblingsInfo(device.get('parent_id')));
    }
  }, [device?.get('parent_id')]);

  useEffect(() => {
    setLastUpdatedAt(deviceStatus?.get('updatedAt'));
    setStatusUpdatedAt(deviceStatus?.get('updatedAt'));
  }, [deviceStatus?.get('updatedAt')]);

  useEffect(() => {
    setLastUpdatedAt(deviceSettings?.get('updatedAt'));
    setSettingsUpdatedAt(deviceSettings?.get('updatedAt'));
  }, [deviceSettings?.get('updatedAt')]);
  useEffect(() => {
    setLastUpdatedAt(device.getIn(['updated_at']));
  }, [device.getIn(['updated_at'])]);

  useEffect(() => {
    if (deviceId && deviceTypeConfig[device.get('type')]?.fetchDeviceStatus) {
      dispatch(fetchDeviceStatus(deviceId));
    }
    if (childTagFromUrl !== 'undefined' && deviceTagFromUrl) {
      dispatch(fetchDevicesWithAlarms(deviceTagFromUrl, childTagFromUrl));
    }
    else {
      dispatch(fetchDevicesWithAlarms(urlDeviceTag));
    }
    if (deviceId) {
      dispatch(fetchDeviceSettings(deviceId));
      dispatch(fetchHiddenCircuits(deviceId));
      setLoading(false);
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceFactory && deviceTypeConfig[device.get('type')]?.fetchDeviceConfigFields) {
      dispatch(fetchDeviceConfigFields(deviceId));
    }
    if (deviceFactory && deviceTypeConfig[device.get('type')]?.fetchDeviceActions) {
      dispatch(fetchDeviceActions(deviceId));
    }
  }, [deviceFactory, deviceId]);


  useEffect(() => {
    if (deviceTypeConfig[device.get('type')]?.activelyMonitored) {
      let interval;
      if (typeof device.getIn(['settings']) !== 'undefined' && !isInactive) {
        if (deviceId) {
          dispatch(fetchDeviceStatus(deviceId));
        }

        interval = setInterval(() => {
          if (deviceId) {
            dispatch(fetchDeviceStatus(deviceId));
            dispatch(fetchDeviceAlarms(deviceId));
          }
        }, device.get('monitoring_interval', 10000) || 10000);
      } else if (!isInactive) {
        return;
      }

      return () => {
        clearInterval(interval);
      };
    }

  }, [isInactive, deviceId]);

  const fetchDeviceAllData = () => {
    if (deviceId) {
      const paginationDetails = {
        filter: '',
        pageSize: 10,
        sort: 0,
        page: 0
      };
      if (device?.get('type') !== 'commLoop') {
        if (isCircuit) {
          dispatch(fetchStatistics(deviceId));
          dispatch(fetchDeviceSensorHistory(deviceId, 'week', 'Day'));
        }

        dispatch(fetchDeviceSettings(deviceId));
        dispatch(fetchDeviceStatus(deviceId));
        dispatch(fetchDevicesAuditTrail(deviceId,
          Map({
            page: 0,
            pageSize: 10,
            pageFilter: null,
            pageSort: null,
          })
        ));
      }

      const optionalFilters = Map({
        deviceId
      });

      dispatch(fetchDevicesActiveAlarms(Map({}), optionalFilters, 0, 10));
      dispatch(fetchDevicesArchivedAlarms(Map({}), optionalFilters, 0, 10));
      dispatch(fetchDevicesActiveNotes(null, Map({}), paginationDetails, false, deviceId));
      dispatch(fetchDevicesArchivedNotes(null, Map({}), paginationDetails, true, deviceId));
      dispatch(fetchDevicesActiveWorkTickets(false, null, 0, 10, null, null, deviceId));
      dispatch(fetchDevicesArchivedWorkTickets(false, null, 0, 10, null, null, deviceId));
      toast.success('Fetching device data...');
    }
  };

  const deviceLastUpdated = () => {
    const timeNow: any = new Date();
    const statusTime: any = new Date(statusUpdatedAt);
    const statusTimeDiff: any = timeNow - statusTime;
    // for Live button
    if (statusTimeDiff < 30000) {
      setIsDeviceLive(true);
    } else {
      setIsDeviceLive(false);
    }

    const settingsTime: any = new Date(settingsUpdatedAt);
    const settingsTimeDiff: any = timeNow - settingsTime;

    // for advanced settings top buttton
    if (settingsTimeDiff < 3600000) {
      setSettingsAreUptoDate(true);
    } else {
      setSettingsAreUptoDate(false);
    }
  };

  useEffect(() => {
    deviceLastUpdated();

    const interval = setInterval(() => {
      deviceLastUpdated();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdatedAt]);


  const toggleAdvancedSettingsModal = () => {
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(deviceId, SettingsModalTypes.DEVICE_ADVANCED_SETTINGS));
    }
    else {
      dispatch(setSettingsModalClose(deviceId));
    }
  };

  const findAdjacentCircuits = (tag) => {
    let prev = null;
    let next = null;

    for (let i=0; i < allSiblingCircuits?.size ; i++) {
      const circuit: any = allSiblingCircuits.get(i);
      if (circuit?.get('tag') === tag) {
        const index = allSiblingCircuits.indexOf(circuit);
        prev = index > 0 ? allSiblingCircuits.get(index - 1) : allSiblingCircuits.get(allSiblingCircuits.size - 1);
        next = index < allSiblingCircuits.size - 1 ? allSiblingCircuits.get(index + 1) : allSiblingCircuits.get(0);
        break;
      }
    }
    if (prev) {
      setPreviousCircuitTag(prev.get('tag'));
    }

    if (next) {
      setNextCircuitTag(next.get('tag'));
    }
  };

  useEffect(() => {
    if (parentIsController && deviceTag && allSiblingCircuits?.size > 1) {
      findAdjacentCircuits(deviceTag);
    }
  }, [deviceTag, allSiblingCircuits.size]);

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  // this code must be just before returning html
  if (!deviceId) {
    return (<NotFoundScreen />);
  }


  return (
    <div id='device-screen' className='page' data-testid='device-screen'>
      <Helmet>
        <title>{`${title} - ${deviceTypeConfig[device.get('type')]?.title}`}</title>
      </Helmet>

      <SettingsMismatchModal
        open={designedSettingsModalIsOpen && designedSettingsModalId === SettingsModalTypes['DESIGNED_SETTINGS_MISMATCH_MODAL']}
        handleClose={() => closeDesignedSettingsModal()}
        settingsDiff={settingsDiff}
        deviceId={deviceId}
        loading={designedSettingsModalIsLoading}
      />

      <div className="device-screen-top-content">
        {
          parentIsController && previousCircuitTag ?
            <Tooltip title={previousCircuitTag || ''} >
              <Link
                className='prev-next-button'
                to={`/devices/${encodeURIComponent(device.get('parent_tag'))}/${encodeURIComponent(previousCircuitTag)}`}
              >
                <PreviousIcon/>
              </Link>
            </Tooltip>
            : null
        }
        <h2>{deviceTag}</h2>
        {
          parentIsController && nextCircuitTag ?
            <Tooltip title={nextCircuitTag || ''} >
              <Link
                className='prev-next-button'
                to={`/devices/${encodeURIComponent(device.get('parent_tag'))}/${encodeURIComponent(nextCircuitTag)}`}
              >
                <NextIcon/>
              </Link>
            </Tooltip>
            : null
        }

      </div><br />
      <div className='top-action-buttons'>
        <Tooltip title='Refetch Device Data'>
          <div className='device-screen-top-button' onClick={fetchDeviceAllData} >
            <RefetchIcon className='help-icon' style={{ fontSize: 16, color: 'white' }} />
          </div>
        </Tooltip>
        {deviceType !== 'commLoop' ?
          <>
            <Tooltip title={settingsUpdatedAt ? `Last fetched device settings at ${moment(settingsUpdatedAt).format('YYYY-MM-DD hh:mm A')}` : `Haven't fetched settings yet`}>
              <div className={settingsAreUptoDate ? 'device-screen-top-button make-button-green' : 'device-screen-top-button make-button-grey'} style={{ cursor: deviceConfigFields?.get('settings').size > 0 ? 'pointer' : 'default' }} onClick={e => deviceConfigFields?.get('settings').size > 0 ? toggleAdvancedSettingsModal() : e.preventDefault() } >
                <SettingsIcon className='help-icon' style={{ fontSize: 16, color: 'white' }} />
              </div>
            </Tooltip>

            <Tooltip title={statusUpdatedAt ? `Last fetched device status at ${moment(statusUpdatedAt).format('YYYY-MM-DD hh:mm A')}` : `Haven't fetched status yet`}>
              <div className={isDeviceLive ? 'device-live-indication make-button-green' : 'device-live-indication make-button-grey'}>
                <PointIcon className='help-icon' style={{ fontSize: 16, color: 'white', paddingTop: '6px' }} />
                <div className='device-screen-live-title'>LIVE</div>
              </div>
            </Tooltip>
          </> : null
        }
      </div>

      {isDeviceInCommFail ? <Banner type='warning' message='Device is Experiencing Communication Failures' /> : null}
      {device.getIn(['status', 'banner'], null) ? <Banner
        type={device.getIn(['status', 'banner', 'type'], 'info')}
        message={device.getIn(['status', 'banner', 'message'], '')}
      />
        : null}
      {!isDeviceInCommFail && deviceSettings?.get('settingConfigurationErrors')?.size > 0 ? <Banner type='warning' message='Invalid Device Setting Configuration'
        button={
          <Button
            onClick={() => setViewConfigSettingsIssue(true)}
            icon={<BuildIcon />}
          />} /> : null}

      <Grid container spacing={3} >
        <Grid item container direction="column" spacing={3} xs={12} md={4} >
          {deviceTypeConfig[device.get('type')]?.properties ?
            <Grid item mb={isMobileOrIpad ? 2 : 0}>
              <DeviceProperties device={device} permission={'edit-device'} configFields={deviceConfigFields} temperatureUnit={temperatureUnit} />
            </Grid> : null}
          {deviceTypeConfig[device.get('type')]?.children ? <Grid item className='child-devices-grid'>
            <Paper>
              <ChildDevices device={device}/>
            </Paper>
          </Grid> : null}
          {/* when on desktop action button are on left side of screen */}
          {!isMobileOrIpad && deviceTypeConfig[device.get('type')]?.actionButtons ? <DeviceScreenActionButtons
            device={device}
            deviceConfigFields={deviceConfigFields}
            loading={loading}
            deviceActions={deviceActions}
          /> : null}
        </Grid>

        <Grid item direction="column" spacing={3} xs={12} md={8} className='mainGrid' >

          {deviceTypeConfig[device.get('type')]?.currentAlarms ?
            <Grid item mb={3}>
              <AlarmsContent title={'Current Alarms'} deviceId={deviceId} refetchInterval={10000} onlyActive />
            </Grid>
            : null}

          {settingsDiff?.size > 0 ? (
            <div className='discrepancy-banner'
              style={{ background: '#fcd54c', width: '100%', cursor: 'pointer', fontWeight: 'bold', textAlign: 'center', borderRadius: '4px', marginBottom: '25px' }}
              onClick={clickDesignedSettingsMismatchIcon}
            >
              Device Settings do not match designed values
              <PreviewIcon fontSize='large' style={{ verticalAlign: '-5px', marginLeft: '10px' }} />
            </div>
          ) : null }

          {deviceTypeConfig[device.get('type')]?.gauges? (
            <Grid item mb={3} >
              <GaugeContainer
                isDeviceInCommFail={isDeviceInCommFail}
                canDisableAllAlarms={device.getIn(['settings', 'allowDisableAllSetpoints'], false)}
                canDisableControlAlarms={device.getIn(['settings', 'allowDisableControlSetpoints'], false)}
                enableSpecificAlarms={device.getIn(['settings', 'enableSpecificAlarms'], null)}
                device={device} deviceStatus={deviceStatus}
                deviceId={deviceId} configFields={deviceConfigFields}
                gaugeStyle={user?.getIn(['preferences', 'gaugeStyle'], 'radial')}
              />
            </Grid>
          ) : null}

          {deviceTypeConfig[device.get('type')]?.statistics ?
            <Grid item mb={3}>
              <DeviceStatistics deviceId={deviceId} numTemperatureSensors={numTemperatureSensors} temperatureUnit={temperatureUnit} />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.sensorGraph ?
            <Grid item mb={3}>
              <DeviceSensorGraph deviceId={deviceId} temperatureUnit={temperatureUnit} />
            </Grid>
            : null
          }

          {deviceTypeConfig[device.get('type')]?.currentNotifications ?
            <Grid item mb={3}>
              <NotificationsContent title={`Current ${pluralWorkTicketLabel}`} deviceId={deviceId} />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.currentNotes ?
            <Grid item mb={3}>
              <NotesContent title={'Current Notes'} deviceId={deviceId} showDeleteIcon />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.pastAlarms ?
            <Grid item mb={3}>
              <AlarmsContent title={'Past Alarms'} deviceId={deviceId} isExpanable onlyArchived />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.pastNotifications ?
            <Grid item mb={3}>
              <NotificationsContent title={`Past ${pluralWorkTicketLabel}`} deviceId={deviceId} isExpanable onlyArchived />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.pastNotes ?
            <Grid item mb={3}>
              <NotesContent title={'Past Notes'} deviceId={deviceId} isExpanable onlyArchived />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.testingReports ?
            <Grid item>
              <TestingReportsContent deviceId={deviceId} />
            </Grid>
            : null
          }
          {deviceTypeConfig[device.get('type')]?.spliceReports &&
          <Grid item>
            <SpliceReportsContent deviceId={deviceId} />
          </Grid>
          }
          {deviceTypeConfig[device.get('type')]?.circuitFunctionTestReports &&
          <Grid item>
            <CircuitFunctionTestReportContent deviceId={deviceId} />
          </Grid>
          }
          {deviceTypeConfig[device.get('type')]?.insulationInspectionTestReports &&
          <Grid item>
            <InsulationInspectionTestReportContent deviceId={deviceId} />
          </Grid>
          }
          {deviceTypeConfig[device.get('type')]?.forcedOnLogs ?
            <Grid item mb={3}>
              <ForcedOnContent deviceId={deviceId} />
            </Grid>
            : null
          }

          {deviceTypeConfig[device.get('type')]?.auditTrail ?
            <Grid item mb={3}>
              <AuditContent deviceId={deviceId} />
            </Grid>
            : null
          }

          {/* when on mobile action buttons are at the bottom of the screen */}
          {isMobileOrIpad && deviceTypeConfig[device.get('type')]?.actionButtons ? <DeviceScreenActionButtons
            device={device}
            deviceConfigFields={deviceConfigFields}
            loading={loading}
            deviceActions={deviceActions}
          /> : null}
        </Grid>
      </Grid>
      <Modal
        data-testid='view-settings-miss-configuration-modal'
        open={viewConfigSettingsIssue}
        onClose={() => setViewConfigSettingsIssue(false)}
        aria-labelledby='view-settings-miss-configuration-title'
      >
        <div className='modal view-settings-miss-configuration-modal'>
          <DataTable
            columns={[{
              id: 'setting',
              Header: 'Setting Misconfiguration Issues',
              accessor: row => langLookUpValidationError(row),
              width: '500px'
            }]}
            data={deviceSettings?.get('settingConfigurationErrors')}
          />
          <div className='button-bar'>
            <Button onClick={() => setViewConfigSettingsIssue(false)}>close</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

DeviceScreen.propTypes = {
  match: PropTypes.object
};

DeviceScreen.defaultProps = {};
