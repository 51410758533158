import { Map, List, mergeDeep } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as devicesActions from './action';
import * as circuitActions from '../circuit/action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import moment from 'moment';

export const initialState = Map({
  devices: Map(),
  deviceTags: List(),
  deviceTree: Map(),
  panels: Map(),
  dashboardStats: Map(),
  sensorHistory: Map,
  settings: List(),
  snapshot: Map(),
  deviceUpgradeStatus: Map,
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState
      .set('devices', Map())
      .set('deviceTags', List())
      .set('deviceTree', Map())
      .set('dashboardStats', Map())
      .set('sensorHistory', Map())
      .set('settings', List())
      .set('siblingsInfo', Map())
      .set('snapshot', Map())
      .set('deviceUpgradeStatus', Map())

  );
}

export const devicesReducer = createTypedReducer(initialState, {

  [devicesActions.FETCH_DEVICES] (state) {
    return state.withMutations((nextState) =>
      nextState.set('loading', true)
    );
  },

  [devicesActions.FETCH_DEVICES_SETTINGS_DIFF_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'settingsDiff'], action.deviceSettingsDiff)
    );
  },

  [devicesActions.FETCH_DEVICES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const lastFetch = new Date();
      if (action.device?.get('id')) {
        nextState.setIn(['devices', 'data', action.device.get('id'), 'record', 'info'], action.device)
          .setIn(['devices', 'data', action.device.get('id'), 'record', 'circuits'], action.circuits ? action.circuits.map(x => x.get('id')) : List())
          .setIn(['devices', 'data', action.device.get('id'), 'lastFetch'], lastFetch);

      }
      if (action.circuit?.get('id')) {
        nextState.setIn(['devices', 'data', action.circuit.get('id'), 'record', 'info'], action.circuit)
          .setIn(['devices', 'data', action.circuit.get('id'), 'lastFetch'], lastFetch);
      }
      if (action.deviceSettingsDiff) {
        nextState.setIn(['devices', 'data', action.circuit?.get('id') || action.controller.get('id'), 'record', 'settingsDiff'], action.deviceSettingsDiff);
      }
      nextState.setIn(['id'], action.deviceId)
        .setIn(['loading'], false);
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICES_WITH_ALARMS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['loading'], true)
    );
  },

  [devicesActions.FETCH_DEVICES_WITH_ALARMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const lastFetch = new Date();
      let childIds = List();

      // child devices
      action.childDevices?.map(child => {
        childIds = childIds.push(child.get('id'));
        nextState.setIn(['devices', 'data', child.get('id'), 'record', 'info'], child)
          .setIn(['devices', 'data', child.get('id'), 'lastFetch'], lastFetch);
      });
      // acton.device could be commLoop/controller/920
      const parent_id = action.parentDevice ? action.parentDevice.get('id') : action.circuit.get('id');
      nextState.setIn(['devices', 'data', parent_id, 'record', 'info'], action.parentDevice)
        .setIn(['devices', 'data', parent_id, 'lastFetch'], lastFetch)
        .setIn(['devices', 'data', parent_id, 'loading'], false)
        .setIn(['devices', 'data', parent_id, 'record', 'childDevices'], childIds)
        .set('loading', false);
      // only for circuit except 920
      if (action.circuit) {
        nextState.setIn(['devices', 'data', action.circuit.get('id'), 'record', 'info'], action.circuit)
          .setIn(['devices', 'data', action.circuit.get('id'), 'lastFetch'], lastFetch)
          .setIn(['devices', 'data', action.circuit.get('id'), 'loading'], false)
          .setIn(['devices', 'data', action.circuit.get('id'), 'record', 'settingsDiff'], action.deviceSettingsDiff);
      } else {
        nextState.setIn(['devices', 'data', parent_id, 'record', 'settingsDiff'], action.deviceSettingsDiff);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_WITH_ALARMS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.set('loading', false)
    );
  },

  [devicesActions.FETCH_DEVICES_PROGRAMMING_DISCREPANCIES] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['programmingDiscrepancies', 'loading'], true)

    );
  },

  [devicesActions.FETCH_PANEL_BY_QR_CODE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['panelDirectory', action.id], action.panelInfo);
    });
  },


  [devicesActions.FETCH_DEVICES_PROGRAMMING_DISCREPANCIES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      let device_ids = List();
      action.deviceProgrammingDiscrepancies.get('diffArray').map(diffObject => {
        device_ids = device_ids.push(diffObject.get('device_id'));
        nextState.setIn(['devices', 'data', diffObject.get('device_id'), 'record', 'info'], diffObject.get('device'));
        nextState.setIn(['devices', 'data', diffObject.get('device_id'), 'record', 'deviceConfigFields'], diffObject.get('deviceConfig'));
        nextState.setIn(['devices', 'data', diffObject.get('device_id'), 'record', 'settingsDiff'], diffObject.get('diffs'));
      });
      nextState.setIn(['programmingDiscrepancies', 'loading'], false);

      nextState.setIn(['programmingDiscrepancies', 'data'], device_ids);
      nextState.setIn(['programmingDiscrepancies', 'page'], action.deviceProgrammingDiscrepancies.get('page'));
      nextState.setIn(['programmingDiscrepancies', 'pageSize'], action.deviceProgrammingDiscrepancies.get('pageSize'));
      nextState.setIn(['programmingDiscrepancies', 'totalPages'], action.deviceProgrammingDiscrepancies.get('totalPages'));
      nextState.setIn(['programmingDiscrepancies', 'total'], action.deviceProgrammingDiscrepancies.get('total'));

      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_PROGRAMMING_DISCREPANCIES_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['programmingDiscrepancies', 'loading'], false)
    );
  },

  [devicesActions.FETCH_SIBLINGS_INFO] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['siblingsInfo', 'loading'], true)
        .setIn(['siblingsInfo', 'data'], Map())
    );
  },

  [devicesActions.FETCH_SIBLINGS_INFO_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.siblingsInfo?.size === 0) {
        return nextState;
      }
      return nextState.setIn(['siblingsInfo', 'loading'], false)
        .setIn(['siblingsInfo', 'data', action.deviceId], action.siblingsInfo);
    });
  },

  [devicesActions.FETCH_SIBLINGS_INFO_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['siblingsInfo', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'loading'], true)
        .setIn(['devices', 'data', action.deviceId, 'loading'], true)
    );
  },

  [devicesActions.REPLACE_CIRCUIT_CARD_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }
      return nextState.setIn(['devices', 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'info'], action.device)
        .setIn(['devices', 'data', action.deviceId, 'lastFetch'], new Date())
        .setIn(['devices', 'data', action.deviceId, 'loading'], false);
    });
  },

  [devicesActions.FETCH_DEVICE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.device.get('id')) {
        return nextState;
      }
      return nextState.setIn(['devices', 'loading'], false)
        .setIn(['devices', 'data', action.device.get('id'), 'record', 'info'], action.device)
        .setIn(['devices', 'data', action.device.get('id'), 'lastFetch'], new Date())
        .setIn(['devices', 'data', action.device.get('id'), 'loading'], false);

    });
  },

  [devicesActions.FETCH_DEVICE_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_INFO] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'loading'], true)
    );
  },

  [devicesActions.FETCH_DEVICE_INFO_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.device.get('id')) {
        return nextState;
      }
      return nextState.setIn(['devices', 'data', action.device.get('id'), 'loading'], false)
        .setIn(['devices', 'data', action.device.get('id'), 'record', 'info'], action.device);
    });
  },

  [devicesActions.FETCH_DEVICE_INFO_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.device.get('id'), 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_TREE] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['deviceTree', 'loading'], true)
    );
  },

  [devicesActions.FETCH_DEVICE_TREE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['deviceTree', 'loading'], false)
        .setIn(['deviceTree', 'data'], action.deviceTree);
    });
  },

  [devicesActions.FETCH_DEVICE_TREE_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['deviceTree', 'data'], false)
        .setIn(['deviceTree', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_STATUS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_STATUS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }
      nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'info', 'status'], action.deviceStatus);
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_STATUS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_SETTINGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }

      if (state.getIn(['snapshot', action.deviceId])) {
        nextState = nextState.setIn(['snapshot', action.deviceId, 'done'], true);
      }

      return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'info', 'settings'], action.deviceSettings);

    });
  },
  [devicesActions.SNAPSHOT_SETTINGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }

      if (state.getIn(['snapshot', action.deviceId])) {
        nextState = nextState.setIn(['snapshot', action.deviceId, 'done'], true);
      }

      return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
    });
  },
  [devicesActions.SNAPSHOT_SETTINGS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }

      if (state.getIn(['snapshot', action.deviceId])) {
        nextState = nextState.setIn(['snapshot', action.deviceId, 'done'], true);
        nextState = nextState.setIn(['snapshot', action.deviceId, 'failed'], true);

      }

      return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
    });
  },
  [devicesActions.SNAPSHOT_SETTINGS_WARNING] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }

      if (state.getIn(['snapshot', action.deviceId])) {
        nextState = nextState.setIn(['snapshot', action.deviceId, 'done'], true);
        nextState = nextState.setIn(['snapshot', action.deviceId, 'warning'], true);

      }

      return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
    });
  },

  [devicesActions.FETCH_DEVICE_SETTINGS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_ENUMERATED_DEVICE_SETTINGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.set('settings', action.settings);
      return nextState;
    });
  },

  [devicesActions.SNAPSHOT_DEVICE_SETTINGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.set('snapshot', action.snapshotStatus);
      return nextState;
    });
  },
  [devicesActions.UPGRADE_A_DEVICE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const snapshots = state.getIn(['deviceUpgradeStatus', 'snapshotDevices']);
      if (snapshots) {
        const newSnapShot = snapshots.map(device => {
          return device.get('deviceId') === action.deviceId ? Map({ ...device.toJS(), done: true }) : device;
        });
        const newCompletedAt = state.getIn(['deviceUpgradeStatus', 'numCompleted']);
        const numDevices = state.getIn(['deviceUpgradeStatus', 'numDevices']);
        const numCompleted = newCompletedAt < numDevices ? newCompletedAt + 1 : numDevices;
        const progress = (numDevices > 0) ? numCompleted / numDevices : 0;
        nextState.setIn(['deviceUpgradeStatus', 'snapshotDevices'], newSnapShot);
        nextState.setIn(['deviceUpgradeStatus', 'numCompleted'], numCompleted);
        nextState.setIn(['deviceUpgradeStatus', 'progress'], progress);
      }

      return nextState;
    });
  },
  [devicesActions.UPGRADE_DEVICE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const deviceUpgradeStatus = action.upgradeStatus;
      let numDevices = 0;
      let numCompleted = 0;
      let snapshotDevices = List();
      deviceUpgradeStatus?.keySeq().forEach((deviceId) => {
        if (deviceId !== 'status') {
          numDevices++;
          const snapshotDevice = deviceUpgradeStatus.get(deviceId);
          if (snapshotDevice.get('done')) {
            numCompleted++;
          }
          snapshotDevices = snapshotDevices.push(snapshotDevice);
        }

      });


      const progress = (numDevices > 0) ? numCompleted / numDevices : 0;


      nextState.set('deviceUpgradeStatus', Map({
        snapshotDevices: snapshotDevices.sort((a: any, b: any) => a.get('tag') > b.get('tag') ? 1:-1),
        numDevices,
        numCompleted,
        progress,
        status: 'inProgress'
      }));
      return nextState;
    });
  },
  [devicesActions.UPGRADE_DEVICE_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['deviceUpgradeStatus', 'status'], 'failed');
      return nextState;
    });
  },

  [devicesActions.CLEAR_DEVICE_SETTINGS_SNAPSHOT] (state) {
    return state.withMutations((nextState) => {
      nextState.delete('snapshot');
      nextState.delete('deviceUpgradeStatus');
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE] (state, device) {
    return state.withMutations((nextState) => {
      if (device.deviceId) {
        nextState.setIn(['devices', 'data', device.deviceId, 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.device.get('id')) {
        return nextState;
      }
      const curDevice = nextState.getIn(['devices', 'data', action.device.get('id'), 'record', 'info'], Map());
      return nextState.setIn(['devices', 'data', action.device.get('id'), 'loading'], false)
        .setIn(['devices', 'data', action.device.get('id'), 'record', 'info'], mergeDeep(curDevice, action.device))
        .setIn(['devices', 'data', action.device.get('id'), 'lastFetch'], new Date);
    });
  },

  [devicesActions.UPDATE_DEVICE_SETTINGS] (state, device) {
    return state.withMutations((nextState) => {
      if (device.deviceId) {
        nextState.setIn(['devices', 'data', device.deviceId, 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE_SETTINGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.device.get('id')) {
        return nextState;
      }

      const deviceId = action.device.get('id');

      if (nextState.getIn(['deviceUpgradeStatus', deviceId])) {
        nextState = nextState.setIn(['deviceUpgradeStatus', deviceId, 'done'], true);
      }

      const curDevice = nextState.getIn(['devices', 'data', deviceId, 'record', 'info'], Map());
      return nextState.setIn(['devices', 'data', deviceId, 'loading'], false)
        .setIn(['devices', 'data', deviceId, 'record', 'info'], mergeDeep(curDevice, action.device))
        .setIn(['devices', 'data', deviceId, 'record', 'info', 'settings', 'settingConfigurationErrors'], action.device.getIn(['settings', 'settingConfigurationErrors']))
        .setIn(['devices', 'data', deviceId, 'lastFetch'], new Date);
    });
  },

  [devicesActions.UPDATE_DEVICE_DESIGNED_SETTINGS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE_DESIGNED_SETTINGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }
      return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'info', 'designed_values'], action.designedValues)
        .setIn(['devices', 'data', action.deviceId, 'lastFetch'], new Date)
        .setIn(['devices', 'data', action.deviceId, 'record', 'settingsDiff'], List());
    });
  },

  [devicesActions.UPDATE_DEVICE_DESIGNED_SETTINGS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE_SETTINGS_FAILED] (state, device) {
    return state.withMutations((nextState) => {
      if (device.deviceId) {
        nextState.setIn(['devices', 'data', device.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE_FAILED] (state, device) {
    return state.withMutations((nextState) => {
      if (device.deviceId) {
        nextState.setIn(['devices', 'data', device.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },
  [devicesActions.ADD_DEVICE_FAILED] (state, device) {
    return state.withMutations((nextState) => {
      if (device.deviceId) {
        nextState.setIn(['devices', 'data', device.deviceId, 'loading'], false);
      }
      nextState.setIn(['devices', 'synchronization', 'error'], true);

      return nextState;
    });
  },

  [devicesActions.SYNC_DEVICE_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'synchronization', 'error'], true);
      return nextState;
    });
  },
  [devicesActions.ADD_DEVICE_FAILED_CLEAN_UP] (state, device) {
    return state.withMutations((nextState) => {
      if (device.deviceId) {
        const index = nextState.getIn(['deviceTree', 'data', 'panels'], List())
          .findIndex((item) => item.get('id') === device.deviceId);
        if (index > -1) {
          nextState.deleteIn(['deviceTree', 'data', 'panels', index]);
        }
        const devices = nextState.getIn(['devices', 'data'], Map()).delete(device.deviceId);
        nextState.setIn(['devices', 'data'], devices);

      }
      return nextState;
    });
  },


  [devicesActions.FETCH_STATISTICS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_STATISTICS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'statistics'], action.statistics);
      }
    });
  },

  [devicesActions.FETCH_STATISTICS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_WORK_TICKETS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_WORK_TICKETS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }
      return nextState
        .setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'data'], action.workTickets.map(x => x.get('id')))
        .setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'total'], action.total)
        .setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'totalPages'], action.numPages);
    });

  },

  [devicesActions.FETCH_DEVICES_ACTIVE_WORK_TICKETS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'activeWorkTickets', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_WORK_TICKETS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_WORK_TICKETS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        return nextState;
      }
      return nextState
        .setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'data'], action.workTickets.map(x => x.get('id')))
        .setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'total'], action.total)
        .setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'totalPages'], action.numPages);
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_WORK_TICKETS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'archivedWorkTickets', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_NOTES] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.UPDATE_DEVICE_NOTE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const noteId = action.note.get('id');
      const deviceId = action.note.get('device_id') || null;
      if (action.note.get('archived_at')) {
        if (deviceId) {
          const deviceNotesLocation = ['devices', 'data', deviceId, 'record', 'activeNotes'];
          const totalNotes = nextState.getIn([...deviceNotesLocation, 'total'], 0);
          nextState.setIn([...deviceNotesLocation, 'data'],
            nextState.getIn([...deviceNotesLocation, 'data'])?.filter(x => x !== noteId));
          nextState.setIn([...deviceNotesLocation, 'total'], totalNotes > 0 ? totalNotes - 1 : 0);
        }
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_NOTES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const ids = action.notes.map(x => x.get('id'));
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'data'], ids);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'loading'], false);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'total'], action.total);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'totalPages'], action.numPages);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_NOTES_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'activeNotes', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_NOTES] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_NOTES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const ids = action.notes.map(x => x.get('id'));
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'data'], ids);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'loading'], false);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'total'], action.total);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'totalPages'], action.numPages);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_NOTES_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'archivedNotes', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_EXPORT] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_EXPORT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const now = moment();
        const today = now.format('YYYY-MM-DD');

        const blob = new Blob([action.export], { type: 'text/csv;charset=utf-8,' });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', objUrl);
        link.setAttribute('download', `${today}-stats.csv`);
        link.click();
        return nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'statExport'], action.export);
      }
    });
  },

  [devicesActions.FETCH_EXPORT_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DASHBOARD_STATS] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['dashboardStats', 'loading'], true)
        .setIn(['dashboardStats', 'data'], Map());
    });
  },

  [devicesActions.FETCH_DASHBOARD_STATS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['dashboardStats', 'loading'], false)
        .setIn(['dashboardStats', 'data'], action.stats);
    });
  },

  [devicesActions.FETCH_DASHBOARD_STATS_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['dashboardStats', 'loading'], false);
    });
  },

  [devicesActions.FETCH_DEVICES_ALARMS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.id) {
        nextState.setIn(['devices', 'data', action.id, 'alarms', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_ALARMS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId && action.showLoadingIndicator) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_ALARMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const ids = action.alarms.map(x => x.get('id'));
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'data'], ids);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'loading'], false);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'total'], action.total);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'totalPages'], action.numPages);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'isControllerInCommFail'], action.isControllerInCommFail);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'isChannelInCommFail'], action.isChannelInCommFail);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_ALARMS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_ALARMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const ids = action.alarms.map(x => x.get('id'));
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'data'], ids);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'loading'], false);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'total'], action.total);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'totalPages'], action.numPages);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_PANEL_ACTIVE_ALARMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.panelId) {
        const ids = action.alarms.map(x => x.get('id'));
        nextState.setIn(['panels', 'data', action.panelId, 'record', 'activeAlarms', 'data'], ids);
        nextState.setIn(['panels', 'data', action.panelId, 'record', 'activeAlarms', 'loading'], false);
        nextState.setIn(['panels', 'data', action.panelId, 'record', 'activeAlarms', 'total'], action.total);
        nextState.setIn(['panels', 'data', action.panelId, 'record', 'activeAlarms', 'totalPages'], action.numPages);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ACTIVE_ALARMS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_ARCHIVED_ALARMS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'record', 'archivedAlarms', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_PANEL_ACTIVE_ALARMS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.panelId) {
        nextState.setIn(['panels', 'data', action.panelId, 'record', 'activeAlarms', 'loading'], false)
          .setIn(['panels', 'data', action.panelId, 'record', 'activeAlarms', 'data'], List());
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_CONFIG_FIELDS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'deviceConfigFields'], action.deviceConfigFields);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_CONFIG_FIELDS_GENERIC_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.models) {
        for (const model of action.models) {
          nextState.setIn(['devices', 'deviceConfigFields', model], action.deviceConfigFieldsGeneric.get(model));
        }
      }
      return nextState;
    });
  },

  [circuitActions.ADD_FORCED_ON_CIRCUIT_SUCCESS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['circuit', 'status'], 'forced_on')
    );
  },

  [devicesActions.SET_SETTINGS_MODAL_OPEN] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        nextState = nextState.setIn(['devices', 'settingsModal', 'isOpen'], true)
          .setIn(['devices', 'settingsModal', 'loading'], false)
          .setIn(['devices', 'settingsModal', 'id'], action.id);
      }
      else {
        nextState = nextState.setIn(['devices', 'data', action.deviceId, 'settingsModal', 'isOpen'], true)
          .setIn(['devices', 'data', action.deviceId, 'settingsModal', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'settingsModal', 'id'], action.id);
      }
      return nextState;
    });
  },

  [devicesActions.SET_SETTINGS_MODAL_CLOSE] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        nextState = nextState.setIn(['devices', 'settingsModal', 'isOpen'], false)
          .setIn(['devices', 'settingsModal', 'loading'], false)
          .setIn(['devices', 'settingsModal', 'id'], null);
      }
      else {
        nextState = nextState.setIn(['devices', 'data', action.deviceId, 'settingsModal', 'isOpen'], false)
          .setIn(['devices', 'data', action.deviceId, 'settingsModal', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'settingsModal', 'id'], null);
      }
      return nextState;
    });
  },

  [devicesActions.LOADING_SETTINGS_MODAL] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        nextState = nextState.setIn(['devices', 'settingsModal', 'loading'], true);
      }
      else {
        nextState = nextState.setIn(['devices', 'data', action.deviceId, 'settingsModal', 'loading'], true);
      }
      return nextState;
    });
  },

  [devicesActions.STOP_LOADING_SETTINGS_MODAL] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.deviceId) {
        nextState = nextState.setIn(['devices', 'settingsModal', 'loading'], false)
          .setIn(['devices', 'settingsModal', 'isOpen'], true);
      } else {
        nextState = nextState.setIn(['devices', 'data', action.deviceId, 'settingsModal', 'loading'], false)
          .setIn(['devices', 'data', action.deviceId, 'settingsModal', 'isOpen'], true);
      }
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_TAGS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['deviceTags', 'loading'], true)
    );
  },

  [devicesActions.FETCH_DEVICE_TAGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('deviceTags', Map({
        data: action.deviceTags,
        loading: false
      }))
    );
  },

  [devicesActions.CLEAR_DEVICE_TAGS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['deviceTags', 'data'], List())
    );
  },

  [devicesActions.FETCH_CONTROL_PANEL_TAGS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['controlPanelTags', 'loading'], true)
    );
  },

  [devicesActions.FETCH_CONTROL_PANEL_TAGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('controlPanelTags', Map({
        data: action.controlPanelTags,
        loading: false
      }))
    );
  },

  [devicesActions.FETCH_CONTROL_PANEL_TAGS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['controlPanelTags', 'loading'], false)
    );
  },

  [devicesActions.CLEAR_CONTROL_PANEL_TAGS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['controlPanelTags', 'data'], List())
    );
  },

  [devicesActions.ADD_COMM_LOOP] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'loading'], true)
    );
  },

  [devicesActions.ADD_COMM_LOOP_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (!action.commLoop.get('id')) {
        return nextState;
      }
      return nextState.setIn(['devices', 'loading'], false)
        .setIn(['devices', 'data', action.commLoop.get('id'), 'record', 'info'], action.commLoop)
        .setIn(['devices', 'data', action.commLoop.get('id'), 'lastFetch'], new Date())
        .setIn(['devices', 'data', action.commLoop.get('id'), 'loading'], false)
        .updateIn(['devices', 'commLoops', 'data'], arr => arr.push(action.commLoop.get('id')));
    });
  },

  [devicesActions.ADD_COMM_LOOP_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'loading'], false)
    );
  },

  [devicesActions.FETCH_COMM_LOOP_TAGS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['commLoopTags', 'loading'], true)
    );
  },

  [devicesActions.FETCH_COMM_LOOP_TAGS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('commLoopTags', Map({
        data: action.commLoopTags,
        loading: false
      }))
    );
  },

  [devicesActions.FETCH_COMM_LOOP_TAGS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['commLoopTags', 'loading'], false)
    );
  },

  [circuitActions.ADD_CIRCUIT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.circuit && action.circuit.get('id') && action.circuit.get('parent_id')) {
        nextState = nextState.updateIn(['devices', 'data', action.circuit.get('parent_id'), 'record', 'childDevices'], arr => arr.push(action.circuit.get('id')));
      }
      return nextState;
    });
  },

  [devicesActions.DELETE_CHILD_DEVICE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['devices', 'data', action.deviceId, 'record', 'childDevices'], List())
        .findIndex((item) => item === action.childId);
      return (index !== -1) ? nextState.deleteIn(['devices', 'data', action.deviceId, 'record', 'childDevices', index]) : nextState;
    });
  },

  [devicesActions.DELETE_COMM_LOOP_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['devices', 'commLoops', 'data'], List())
        .findIndex((item) => item === action.deviceId);

      return (index !== -1) ? nextState.deleteIn(['devices', 'commLoops', 'data', index]) : nextState;
    });
  },

  [devicesActions.UPDATE_PANEL] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'loading'], true)
    );
  },

  [devicesActions.UPDATE_PANEL_SUCCESS] (state) {
    return state.withMutations((nextState) => {
      return nextState.setIn(['devices', 'loading'], false);
    });
  },

  [devicesActions.UPDATE_PANEL_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'loading'], false)
    );
  },

  [devicesActions.LOAD_DEVICES_FROM_CIRCUITS_LIST] (state, action) {
    return state.withMutations((nextState) => {
      const lastFetch = new Date();
      action.devices.map(device => {
        if (action.alarmSummary && device.get('id')) {
          const alarmInfo = action.alarmSummary.get(device.get('id'), '');
          nextState.setIn(['devices', 'data', device.get('id'), 'record', 'alarmSummary'], alarmInfo);
        }
        const existingInfo = state.getIn(['devices', 'data', device.get('id'), 'record', 'info']);
        const mergedInfo = existingInfo ? mergeDeep(existingInfo, device) : device;

        nextState.setIn(['devices', 'loading'], false)
          .setIn(['devices', 'data', device.get('id'), 'record', 'info'], mergedInfo)
          .setIn(['devices', 'data', device.get('id'), 'lastFetch'], lastFetch);
      });
      return nextState;
    });
  },

  [devicesActions.DELETE_DEVICE] (state, action) {

    if (!action.deviceId) return state;

    return state.withMutations((nextState) => {
      const devicesDataPath = ['devices', 'data'];
      const devicesData = nextState.getIn(devicesDataPath, Map());

      // Fetch the device to check its type
      const device = devicesData.getIn([action.deviceId, 'record', 'info'], Map());

      // If the device is a controller, delete its circuits
      if (device.get('type') === 'controller') {
        const allDeviceIds = devicesData.keySeq().toArray();
        // Use reduce to delete devices whose parent_id matches the controller's id
        const updatedDevices = allDeviceIds.reduce((acc, id) => {
          const aDevice = devicesData.getIn([id, 'record', 'info'], Map());

          if (aDevice.get('parent_id') === action.deviceId) {
            return acc.delete(id);
          }
          return acc;
        }, devicesData);

        // Set updated device map without circuits
        nextState.setIn(devicesDataPath, updatedDevices);
      }

      // Finally, delete the device itself
      const devicesWithoutController = nextState.getIn(devicesDataPath, Map()).delete(action.deviceId);
      nextState.setIn(devicesDataPath, devicesWithoutController);

      return nextState;
    });
  },

  [devicesActions.DELETE_ALL_CIRCUITS_UNDER_CONTROLLER] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const controller = state.getIn(['devices', 'data', action.deviceId], Map());
        const circuitIds = controller.getIn(['record', 'childDevices'], List());
        const current = nextState.getIn(['devices', 'data'], Map());
        const newMap = current.filter(x => !circuitIds.includes(x.getIn(['record', 'info', 'id'])));
        nextState.setIn(['devices', 'data'], newMap);
      }
      return nextState;
    });
  },

  [devicesActions.DISMISS_DEVICE_ALARMS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'data', action.pageInfo.deviceId, 'record', 'activeAlarms', 'loading'], true);
    });
  },

  [devicesActions.DISMISS_DEVICE_ALARMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const remainingAlarms = nextState.getIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'data'], List()).filter(id => !action.alarmIds.includes(id));

      nextState = nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'data'], remainingAlarms);
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'loading'], false);

      const total = nextState.getIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'total'], 0);
      return nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'total'], total - action.alarmIds.length);
    });
  },

  [devicesActions.DISMISS_DEVICE_ALARMS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'activeAlarms', 'loading'], false);
    });
  },

  [devicesActions.UPDATE_DEVICE_FILE_CONTENTS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], true)
    );
  },

  [devicesActions.UPDATE_DEVICE_FILE_CONTENTS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'records', `${action.updatedFile.get('id')}`], {
        id: action.updatedFile.get('id'),
        size: action.updatedFile.get('size'),
        name: action.updatedFile.get('file_name'),
        type: action.updatedFile.get('type'),
        created_at: action.updatedFile.get('created_at'),
        sub_type: action.updatedFile.get('sub_type'),
        version: action.updatedFile.get('version')
      })
        .setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.UPDATE_DEVICE_FILE_CONTENTS_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.UPLOAD_DEVICE_FILE] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], true)
    );
  },

  [devicesActions.UPLOAD_DEVICE_FILE_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'records', `${action.importFile.get('id')}`], {
        id: action.importFile.get('id'),
        size: action.importFile.get('size'),
        name: action.importFile.get('file_name'),
        type: action.importFile.get('type'),
        created_at: action.importFile.get('created_at'),
        sub_type: action.importFile.get('sub_type'),
        version: action.importFile.get('version')
      })
        .setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.UPLOAD_DEVICE_FILE_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.REMOVE_DEVICE_FILE] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], true)
    );
  },

  [devicesActions.REMOVE_DEVICE_FILE_SUCCESS] (state, action) {
    const records = state.getIn(['devices', 'data', action.deviceId, 'record', 'files', 'records'], Map()).delete(action.fileId);
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'records'], records)
        .setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.REMOVE_DEVICE_FILE_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_FILES] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], true)
    );
  },

  [devicesActions.FETCH_DEVICE_FILES_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      action.files.map((file: any) => {
        nextState = nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'records', file.get('id')], {
          id: file.get('id'),
          name: file.get('file_name'),
          created_at: file.get('created_at'),
          size: file.get('size'),
          description: file.get('description'),
          sub_type: file.get('sub_type') === '' ? 'No Type' : file.get('sub_type'),
          version: file.get('version')
        });
      });

      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false);
    });
  },

  [devicesActions.FETCH_DEVICE_FILES_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.UPDATE_DEVICE_FILES_DESCRIPTION] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], true)
    );
  },

  [devicesActions.UPDATE_DEVICE_FILES_DESCRIPTION_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'files', 'records', `${action.fileId}`, 'description'], action.description)
    );
  },

  [devicesActions.UPDATE_DEVICE_FILES_DESCRIPTION_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
    );
  },

  [devicesActions.UPDATE_DEVICE_FILES_SUB_TYPE_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'files', 'loading'], false)
        .setIn(['devices', 'data', action.deviceId, 'record', 'files', 'records', `${action.fileId}`, 'sub_type'], action.subType)
    );
  },

  [devicesActions.FETCH_SENSOR_HISTORY] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['sensorHistory', 'loading'], true)
    );
  },

  [devicesActions.FETCH_SENSOR_HISTORY_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['sensorHistory', action.timeFrame, action.rollUp], action.sensorData)
        .setIn(['sensorHistory', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_SENSOR_HISTORY] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'sensorHistory', 'loading'], true)
    );
  },

  [devicesActions.FETCH_DEVICE_SENSOR_HISTORY_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'sensorHistory', action.timeFrame, action.rollUp], action.sensorData)
        .setIn(['devices', 'data', action.deviceId, 'sensorHistory', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_SENSOR_HISTORY_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['devices', 'data', action.deviceId, 'sensorHistory', 'loading'], false)
    );
  },

  [devicesActions.FETCH_DEVICE_ACTIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'deviceActions'], action.deviceActions);
      }
      return nextState;
    });
  },

  [devicesActions.DISPATCH_DEVICE_ACTION] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'deviceActions', 'loading'], false);
      }
      return nextState;
    });
  },

  [devicesActions.SYNC_DEVICES] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'synchronization', 'loading'], true);
      nextState.setIn(['devices', 'synchronization', 'showPopup'], true);
      nextState.setIn(['devices', 'synchronization', 'currentStage'], 1);
      nextState.setIn(['devices', 'synchronization', 'totalStages'], 3);
      nextState.setIn(['devices', 'synchronization', 'createdDevices'], null);
      nextState.setIn(['devices', 'synchronization', 'info'], null);
      nextState.setIn(['devices', 'synchronization', 'error'], false);
      nextState.setIn(['devices', 'synchronization', 'deviceTag'], action.deviceTag);
      nextState.setIn(['devices', 'synchronization', 'deviceId'], action.deviceId);
      nextState.setIn(['devices', 'synchronization', 'attemptNumber'], action.attemptNumber ?? state.getIn(['devices', 'synchronization', 'attemptNumber'], 0) + 1);

      return nextState;
    });
  },
  [devicesActions.SYNC_DEVICE_INFO_UPDATE] (state, action) {
    return state.withMutations((nextState) => {
      if (action.createdDevices) {
        nextState = nextState.setIn(['devices', 'synchronization', 'createdDevices'], action.createdDevices);
      }
      if (action.info) {
        if (action.info.get('tag')) {
          nextState = nextState.updateIn(
            ['devices', 'synchronization', 'createdDevices',  `${action.info.get('address')}`],
            (device = Map()) => device.set('status', action.isSuccess )
          );

          if (action.isSuccess === 'success') {
            nextState = nextState.updateIn(
              ['devices', 'synchronization', 'createdDevices',  `${action.info.get('address')}`],
              (device = Map()) => device.set('tag', action.info.get('tag'))
            );
            nextState = nextState.updateIn(
              ['devices', 'synchronization', 'createdDevices',  `${action.info.get('address')}`],
              (device = Map()) => device.set('address',  `${action.info.get('address')}`)
            );
          }

        } else {
          action.info.map(x => {
            nextState = nextState.updateIn(
              ['devices', 'synchronization', 'createdDevices',  `${x.get('address')}`],
              (device = Map()) => device.set('status', action.isSuccess)
            );
            if (action.isSuccess === 'success') {
              nextState = nextState.updateIn(
                ['devices', 'synchronization', 'createdDevices',  `${x.get('address')}`],
                (device = Map()) => device.set('tag', x.get('tag'))
              );
            }
          });



        }

      }

      return nextState;
    });
  },
  [devicesActions.CLOSE_SYNC_POP_UP] (state,) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'synchronization', 'attemptNumber'], 0);
      return nextState.setIn(['devices', 'synchronization', 'showPopup'], false);
    });
  },


  [devicesActions.SYNC_DEVICES_SUCCESS] (state,) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'synchronization', 'loading'], false);
      return nextState;
    });
  },

  [devicesActions.SYNC_DEVICES_STAGE_COMPLETE] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'synchronization', 'currentStage'], action.stage);
      nextState.setIn(['devices', 'synchronization', 'totalStages'], action.totalStages);

      return nextState;
    });
  },
  [devicesActions.FETCH_COMM_LOOPS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      let device_ids = List();
      action.commLoops.get('data').map(rec => {
        device_ids = device_ids.push(rec.get('id'));

        nextState.setIn(['devices', 'data', rec.get('id'), 'record', 'info'], rec);
      });
      nextState.setIn(['devices', 'commLoops', 'loading'], false)
        .setIn(['devices', 'commLoops', 'data'], device_ids)
        .setIn(['devices', 'commLoops', 'page'], action.commLoops.get('page'))
        .setIn(['devices', 'commLoops', 'pageSize'], action.commLoops.get('pageSize'))
        .setIn(['devices', 'commLoops', 'totalPages'], action.commLoops.get('totalPages'))
        .setIn(['devices', 'commLoops', 'total'], action.commLoops.get('total'));
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_AUDIT_TRAIL] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'loading'], true);
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_AUDIT_TRAIL_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'data'], action.auditTrail.get('data'))
        .setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'page'], action.auditTrail.get('page'))
        .setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'total'], action.auditTrail.get('total'))
        .setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'totalPages'], action.auditTrail.get('totalPages'))
        .setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'loading'], false);
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICES_AUDIT_TRAIL_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['devices', 'data', action.deviceId, 'record', 'auditTrail', 'loading'], false);
      return nextState;
    });
  },


  [devicesActions.ACKNOWLEDGE_PROGRAMMING_DISCREPANCY_SUCCESS] (state, action) {
    const deviceId = action.deviceId;
    const acknowledged = action.discrepancy.get('acknowledged');
    return state.setIn(['devices', 'data', deviceId, 'record', 'info', 'discrepancy_acknowledged'], acknowledged);
  },

  [devicesActions.FORCED_ON_PROGRAMMING_DISCREPANCY_SUCCESS] (state, action) {
    const deviceId = action.deviceId;
    return state.setIn(['devices', 'data', deviceId, 'record', 'info', 'has_forced_on_record'], true);
  },


  [devicesActions.CLEAR_SYNC_DEVICES_LOADING] (state) {
    return state.withMutations((nextState) => {
      nextState
        .setIn(['devices', 'synchronization', 'loading'], false);
      return nextState;
    });
  },

  [devicesActions.FETCH_DEVICE_CHECK_SHEETS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'loading'], true);
      }
    }
    );
  },
  [devicesActions.FETCH_DEVICE_CHECK_SHEETS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const ids = action.data.get('data').map(x => x.get('id'));
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'data'], ids);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'loading'], false);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'total'], action.data.get('total'));
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'totalPages'], action.data.get('totalPages'));
      }
    });
  },
  [devicesActions.FETCH_DEVICE_CHECK_SHEETS_FAILED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'loading'], false);
      }
    });
  },

  [devicesActions.FETCH_HIDDEN_CIRCUITS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        nextState.setIn(['devices', 'data', action.deviceId, 'record', 'hiddenCircuits'], action.data);
      }
    });
  },
  [devicesActions.ADD_DEVICE_CHECK_SHEET_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.deviceId) {
        const ids = nextState.getIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'data'], List());
        ids.push(action.checkSheetId);
        nextState.setIn(['devices', 'data', action.deviceId, 'record', action.checkSheetType, 'data'],  ids.push(action.checkSheetId));
      }
    });
  },
  [devicesActions.CLEAR_TESTING_REPORT] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports',  action.reportType], null);
    });
  },
  [devicesActions.GENERATE_TESTING_REPORT] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports', 'loading'], true);
    });
  },
  [devicesActions.GENERATE_TESTING_REPORT_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports', action.reportType], action.data)
        .setIn(['reports', 'loading'], false);
    });
  },
  [devicesActions.GENERATE_TESTING_REPORT_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports', 'loading'], false);
    });
  },
  [devicesActions.GENERATE_TESTING_REPORT_PDF] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports', 'pdf', 'loading'], true);
    });
  },
  [devicesActions.GENERATE_TESTING_REPORT_PDF_SUCCESS] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports', 'pdf', 'loading'], false);
    });
  },
  [devicesActions.GENERATE_TESTING_REPORT_PDF_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['reports', 'pdf', 'loading'], false);
    });
  },





  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  },
});

export default devicesReducer;
