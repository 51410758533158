import { take, takeEvery, takeLatest, takeLeading, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';
import _ from 'lodash';
import { getFileExtension } from 'src/utils/utils';

import * as api from 'src/service/api';
import * as devicesApi from 'src/service/api/devices';
import * as controlPanelsApi from 'src/service/api/controlPanels';
import * as alarmsApi from 'src/service/api/alarms';
import * as checkSheetApi from 'src/service/api/checkSheet';

import * as devicesActions from './action';
import * as notesActions from '../note/action';

import * as alarmsActions from '../alarm/action';
import * as systemActions from '../system/action';
import * as workTicketsActions from '../workTicket/action';
import * as navigationActions from 'src/module/navigation/action';
import * as batchScheduleActions from '../batchSchedule/action';
import * as checkSheetActions from '../checkSheet/action';
import * as circuitActions from '../circuit/action';



import toast from 'src/utils/toast';
import * as autobahnActions from 'src/module/autobahn/action';

import { createUserFriendlyErrorMessage } from 'src/utils/utils';

export function* devicesRoot () {
  yield put(autobahnActions.registerChannel('device', channelConsumer));
  yield takeLatest(devicesActions.FETCH_DEVICE_FILE, fetchDeviceFile);
  yield takeLatest(devicesActions.UPLOAD_DEVICE_FILE, uploadDeviceFile);
  yield takeLatest(devicesActions.UPDATE_DEVICE_FILE_CONTENTS, updateDeviceFileContents);
  yield takeLatest(devicesActions.REMOVE_DEVICE_FILE, removeDeviceFile);
  yield takeLatest(devicesActions.UPDATE_DEVICE_FILES_DESCRIPTION, updateDeviceFilesDescription);
  yield takeLatest(devicesActions.FETCH_DEVICE_FILES, fetchDeviceFiles);
  yield takeLatest(devicesActions.FETCH_DEVICES, fetchDevices);
  yield takeLatest(devicesActions.FETCH_DEVICES_WITH_ALARMS, fetchDevicesWithGroupedAlarms);
  yield takeLatest(devicesActions.FETCH_DEVICES_ACTIVE_ALARMS, fetchDevicesActiveAlarms);
  yield takeLatest(devicesActions.FETCH_PANEL_ACTIVE_ALARMS, fetchPanelActiveAlarms);
  yield takeLatest(devicesActions.FETCH_DEVICES_ARCHIVED_ALARMS, fetchDevicesArchivedAlarms);
  yield takeLatest(devicesActions.FETCH_DEVICE_CONFIG_FIELDS, fetchDeviceConfigFields);
  yield takeLatest(devicesActions.FETCH_DEVICE_CONFIG_FIELDS_GENERIC, fetchDeviceConfigFieldsGeneric);
  yield takeLatest(devicesActions.FETCH_DEVICE, fetchDevice);
  yield takeLatest(devicesActions.FETCH_DEVICE_INFO, fetchDeviceInfo);
  yield takeLatest(devicesActions.FETCH_DEVICE_SETTINGS, fetchDeviceSettings);
  yield takeLatest(devicesActions.FETCH_ENUMERATED_DEVICE_SETTINGS, fetchEnumeratedDeviceSettings);
  yield takeEvery(devicesActions.SNAPSHOT_DEVICE_SETTINGS, snapshotDeviceSettings);
  yield takeEvery(devicesActions.UPGRADE_DEVICE, upgradeDevice);
  yield takeLeading(devicesActions.FETCH_DEVICE_STATUS, fetchDeviceStatus);
  yield takeLatest(devicesActions.UPDATE_DEVICE, updateDevice);
  yield takeLatest(devicesActions.UPDATE_DEVICE_SETTINGS, updateDeviceSettings);
  yield takeLatest(devicesActions.FETCH_DEVICE_TAGS, fetchDeviceTags);
  yield takeLatest(devicesActions.FETCH_CONTROL_PANEL_TAGS, fetchControlPanelTags);
  yield takeLatest(devicesActions.FETCH_DEVICE_TREE, fetchDeviceTree);
  yield takeLatest(devicesActions.UPDATE_PANEL, updatePanel);
  yield takeLatest(devicesActions.DELETE_PANEL, deletePanel);
  yield takeLatest(devicesActions.FETCH_STATISTICS, fetchStatistics);
  yield takeLatest(devicesActions.DELETE_CHILD_DEVICE, deleteChildDevice);
  yield takeLatest(devicesActions.FETCH_EXPORT, fetchExport);
  yield takeLatest(devicesActions.FETCH_DEVICE_ALARMS, fetchDeviceAlarms);
  yield takeLatest(devicesActions.FETCH_DASHBOARD_STATS, fetchDashboardStats);
  yield takeLatest(devicesActions.DISMISS_DEVICE_ALARMS, dismissDeviceAlarms);
  yield takeLatest(devicesActions.DISPATCH_DEVICE_ACTION, dispatchDeviceAction);
  yield takeLatest(devicesActions.FETCH_DEVICE_ACTIONS, fetchDeviceActions);
  yield takeLatest(devicesActions.ADD_COMM_LOOP, addCommLoop);
  yield takeLatest(devicesActions.FETCH_COMM_LOOP_TAGS, fetchCommLoopTags);

  yield takeLatest(devicesActions.FETCH_DEVICES_ACTIVE_NOTES, fetchDevicesActiveNotes);
  yield takeLatest(devicesActions.FETCH_DEVICES_ARCHIVED_NOTES, fetchDevicesArchivedNotes);
  yield takeLatest(devicesActions.FETCH_DEVICES_ACTIVE_WORK_TICKETS, fetchDevicesActiveWorkTickets);
  yield takeLatest(devicesActions.FETCH_DEVICES_ARCHIVED_WORK_TICKETS, fetchDevicesArchivedWorkTickets);
  yield takeLatest(devicesActions.FETCH_SENSOR_HISTORY, fetchSensorHistory);
  yield takeLatest(devicesActions.FETCH_DEVICE_SENSOR_HISTORY, fetchDeviceSensorHistory);
  yield takeLatest(devicesActions.UPDATE_DEVICE_SETTINGS_FAILED, updateDeviceSettingsFailed);
  yield takeLatest(devicesActions.FETCH_COMM_LOOPS, fetchCommLoops);
  yield takeLatest(devicesActions.FETCH_DEVICES_SETTINGS_DIFF, fetchDeviceSettingsDiff);
  yield takeLatest(devicesActions.FETCH_DEVICES_AUDIT_TRAIL, fetchDevicesAuditTrail);
  yield takeLatest(devicesActions.ACKNOWLEDGE_PROGRAMMING_DISCREPANCY, acknowledgeProgrammingDiscrepancy);
  yield takeLatest(devicesActions.UPDATE_DEVICE_DESIGNED_SETTINGS, updateDeviceDesignedSettings);
  yield takeLatest(devicesActions.REPLACE_CIRCUIT_CARD, replaceCircuitCard);
  yield takeLatest(devicesActions.DELETE_COMM_LOOP, deleteCommLoop);
  yield takeLatest(devicesActions.ADD_DEVICE_FAILED, addDeviceFailed);
  yield takeLatest(devicesActions.SYNC_UIT_DEVICES, syncUitDevices);
  yield takeEvery(devicesActions.FETCH_DEVICE_CHECK_SHEETS, fetchDeviceCheckSheets);
  yield takeLatest(devicesActions.FETCH_HIDDEN_CIRCUITS, fetchHiddenCircuits);
  yield takeLatest(devicesActions.UNHIDE_CIRCUIT, unhideCircuit);
  yield takeLatest(devicesActions.FETCH_SIBLINGS_INFO, fetchSiblingsInfo);
  yield takeLatest(devicesActions.UPDATE_DEVICE_FILES_SUB_TYPE, updateDeviceFilesSubType);
  yield takeLatest(devicesActions.GENERATE_TESTING_REPORT, generateTestingReport);
  yield takeLatest(devicesActions.GENERATE_TESTING_REPORT_PDF, generateTestingReportPdf);
  yield takeLatest(devicesActions.FETCH_PANEL_BY_QR_CODE, fetchPanelByQrCode);

  yield takeLatest(devicesActions.FETCH_DEVICES_PROGRAMMING_DISCREPANCIES, fetchDevicesProgrammingDiscrepancies);
}

function* fetchDevices (action) {
  try {
    // making the API call to get controller and circuit. and storing response into devices object
    const devices = yield call(devicesApi.getDevices, action, action.deviceTag, action.circuitTag);
    const deviceId = devices.getIn(['circuit', 'id'], devices.getIn(['device', 'id']));
    yield put(devicesActions.fetchDevicesSuccess(devices.get('device'), devices.get('circuit'), devices.get('circuits'), deviceId, devices.get('settingsDiff')));
  } catch (err: any) {
    yield put(devicesActions.fetchDevicesFailed(Map(err)));
    toast.error("Error retrieving device information", err.response.status);
  }
}

function* syncUitDevices (action) {
  try {
    action.deviceAction = 'SYNC_UIT_DEVICES';
    const response = yield call(devicesApi.dispatchDeviceAction, action);
    if (response) {
      yield put(devicesActions.syncDevices(action.deviceId, action.deviceTag, 1)); // need to send device tag
      yield put(systemActions.registerMessageResponseHandler(response.get('messageId'),
        [],
        [
          {
            action: devicesActions.stopLoadingSettingsModal,
            params: [null, null],
            toast: 'Error'
          },
          {
            action: devicesActions.clearSyncDevicesLoading,
            params: [null],
          },
          {
            action: devicesActions.syncDeviceFailed,
            params: []
          }
        ],
      ));
    }
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error initializing device'), err.response.status);
  }
}

function* replaceCircuitCard (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(action.deviceId));
    yield call(devicesApi.replaceCircuitCard, action, action.deviceId, action.newAddress);
  } catch (err: any) {
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), action.deviceId));
    toast.error(createUserFriendlyErrorMessage(err, 'Error replacing card'), err.response.status);
  }
}
function* fetchDevicesAuditTrail (action) {
  try {
    const trail = yield call(devicesApi.getDevicesAuditTrail, action);
    yield put(devicesActions.fetchDevicesAuditTrailSuccess(action.deviceId, trail));
  } catch (err: any) {
    yield put(devicesActions.fetchDevicesAuditTrailFailed(Map(err)));
    toast.error("Error retrieving device audit trail", err.response.status);
  }
}

function* acknowledgeProgrammingDiscrepancy (action) {
  try {
    const discrepancy = yield call(devicesApi.acknowledgeProgrammingDiscrepancy, action);
    yield put(devicesActions.acknowledgeProgrammingDiscrepancySuccess(discrepancy, action.deviceId));
  } catch (err: any) {
    yield put(devicesActions.acknowledgeProgrammingDiscrepancyFailed(Map(err)));
    toast.error("Error acknowledging device audit", err.response.status);
  }
}
function* addDeviceFailed (action) {
  try {
    // need to delete record from db and remove from front-end
    yield call(devicesApi.deleteDevice, action, action.deviceId);
    yield put(devicesActions.addDeviceFailedCleanUp(action.deviceId));
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
}
function* addCommLoop (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(null));

    const commLoop = yield call(devicesApi.addCommLoop, action);

    yield put(devicesActions.addCommLoopSuccess(commLoop));
    yield put(devicesActions.setSettingsModalClose(null));
    yield put(devicesActions.fetchCommLoops());
  }
  catch (err: any) {
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), null));
    toast.error(createUserFriendlyErrorMessage(err, 'Error adding Comm Loop'), err.response.status);
  }
}

function* fetchCommLoops (action) {
  try {
    const commLoops = yield call(devicesApi.getCommLoops, action);
    yield put(devicesActions.fetchCommLoopsSuccess(commLoops));
  } catch (err: any) {
    toast.error('Error retrieving comm loops', err.response.status);
  }
}

function* fetchDeviceSensorHistory (action) {
  try {
    const data = yield call(devicesApi.getDevicesSensorHistory, action);
    yield put(devicesActions.fetchDeviceSensorHistorySuccess(action.deviceId, data, action.timeFrame, action.rollUp));
  } catch (err: any) {
    yield put(devicesActions.fetchDeviceSensorHistoryFailed(action.deviceId));
    toast.error("Error retrieving device sensor history", err.response.status);
  }
}

function* fetchDevicesWithGroupedAlarms (action) {
  try {
    // making the API call to get controller and circuit. and storing response into devices object
    const devices = yield call(devicesApi.getDevicesWithGroupedAlarms, action, action.deviceTag, action.childTag);
    yield put(devicesActions.fetchDevicesWithAlarmsSuccess(devices.get('device'), devices.get('circuit'), devices.get('childDevices'), devices.get('settingsDiff')));
  } catch (err: any) {
    yield put(devicesActions.fetchDevicesWithAlarmsFailed(Map(err)));
    toast.error("Error retrieving device information", err.response.status);
  }
}

function* fetchSiblingsInfo (action) {
  try {
    const siblingsInfo = yield call(devicesApi.getSiblingsInfo, action);
    yield put(devicesActions.fetchSiblingsInfoSuccess(action.deviceId, siblingsInfo));
  } catch (err: any) {
    yield put(devicesActions.fetchSiblingsInfoFailed(Map(err)));
  }
}


function* fetchDeviceTags (action) {
  try {
    const deviceTags = yield call(devicesApi.getDeviceTags, action);

    yield put(devicesActions.fetchDeviceTagsSuccess(deviceTags));
  } catch (err: any) {
    yield put(devicesActions.fetchDeviceTagsFailed(Map(err)));
  }
}

function* fetchControlPanelTags (action) {
  try {
    const panelTags = yield call(controlPanelsApi.getControlPanelTags, action);
    yield put(devicesActions.fetchControlPanelTagsSuccess(panelTags));
  } catch (err: any) {
    yield put(devicesActions.fetchControlPanelTagsFailed(Map(err)));
  }
}

function* fetchCommLoopTags (action) {
  try {
    const commLoopTags = yield call(devicesApi.getCommLoopTags, action);
    yield put(devicesActions.fetchCommLoopTagsSuccess(commLoopTags));
  } catch (err: any) {
    yield put(devicesActions.fetchCommLoopTagsFailed(Map(err)));
  }
}

function* fetchDevicesActiveWorkTickets (action) {
  try {
    const notifications = yield call(api.getNotificationsByDevice, action, true, false, action.page, action.pageSize, action.filter, action.sort);
    yield put(workTicketsActions.loadWorkTicketsFromDevice(notifications.get('data')));
    yield put(devicesActions.fetchDevicesActiveWorkTicketsSuccess(notifications.get('data'), action.deviceId, notifications.get('total'), notifications.get('totalPages', 1)));
  } catch (err: any) {
    yield put(devicesActions.fetchDevicesActiveWorkTicketsFailed(Map(err), action.deviceId));
    toast.error("Error retrieving device notifications", err.response.status);
  }
}

function* fetchDevicesArchivedWorkTickets (action) {
  try {
    const notifications = yield call(api.getNotificationsByDevice, action, false, true, action.page, action.pageSize, action.filter, action.sort);
    yield put(workTicketsActions.loadWorkTicketsFromDevice(notifications.get('data')));
    yield put(devicesActions.fetchDevicesArchivedWorkTicketsSuccess(notifications.get('data'), action.deviceId, notifications.get('total'), notifications.get('totalPages', 1)));
  } catch (err: any) {
    yield put(devicesActions.fetchDevicesArchivedWorkTicketsFailed(Map(err), action.deviceId));
    toast.error("Error retrieving device notifications", err.response.status);
  }
}

function* fetchDevicesActiveNotes (action) {
  try {
    const notes = yield call(api.getNotesByDevice, action, true, false);
    yield put(notesActions.loadNotesFromDevice(notes.get('data')));
    yield put(devicesActions.fetchDevicesActiveNotesSuccess(notes.get('data'), action.deviceId, notes.get('total', 0), notes.get('totalPages', 0)));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDevicesActiveNotesFailed(Map(err), action.deviceId));
    toast.error("Error retrieving device notes Information", err.response.status);
  }
}
function* fetchDevicesArchivedNotes (action) {
  try {
    const notes = yield call(api.getNotesByDevice, action, false, true);
    yield put(notesActions.loadNotesFromDevice(notes.get('data')));
    yield put(devicesActions.fetchDevicesArchivedNotesSuccess(notes.get('data'), action.deviceId, notes.get('total'), notes.get('totalPages', 1)));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDevicesArchivedNotesFailed(Map(err), action.deviceId));
    toast.error("Error retrieving device notes Information", err.response.status);
  }
}

function* fetchDevicesActiveAlarms (action) {
  try {
    const alarms = yield call(alarmsApi.getAlarms, action, true);
    yield put(alarmsActions.loadAlarmsFromDevice(alarms.get('data')));
    yield put(devicesActions.fetchDevicesActiveAlarmsSuccess(alarms.get('data'), action.optionalFilters.get('deviceId'), alarms.get('total'), alarms.get('totalPages', 1), alarms.get('isChannelInCommFail', false), alarms.get('isControllerInCommFail', false)));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDevicesActiveAlarmsFailed(Map(err), action.deviceId));
    toast.error("Error retrieving device Alarms Information", err.response.status);
  }
}
function* fetchPanelActiveAlarms (action) {
  try {
    const alarms = yield call(alarmsApi.getAlarms, action, true);
    yield put(alarmsActions.loadAlarmsFromDevice(alarms.get('data')));
    yield put(devicesActions.fetchPanelActiveAlarmsSuccess(alarms.get('data'), action.optionalFilters.get('panelId'), alarms.get('total'), alarms.get('totalPages', 1)));
  }
  catch (err: any) {
    yield put(devicesActions.fetchPanelActiveAlarmsFailed(Map(err), action.optionalFilters.get('panelId')));
    toast.error("Error retrieving device Alarms Information", err.response.status);
  }
}

function* fetchDevicesArchivedAlarms (action) {
  try {
    const alarms = yield call(alarmsApi.getAlarms, action, false);
    yield put(alarmsActions.loadAlarmsFromDevice(alarms.get('data')));
    yield put(devicesActions.fetchDevicesArchivedAlarmsSuccess(alarms.get('data'), action.optionalFilters.get('deviceId'), alarms.get('total'), alarms.get('totalPages', 1)));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDevicesArchivedAlarmsFailed(Map(err), action.optionalFilters.get('deviceId')));
    toast.error("Error retrieving device Alarms Information", err.response.status);
  }
}
function* updatePanel (action) {
  const isCreate = action?.panel?.id === null;
  try {
    yield call(controlPanelsApi.updatePanel, action, action.panel);

    yield put(devicesActions.updatePanelSuccess(action.panel));
    const deviceTree = yield call(devicesApi.getDeviceTree, action);

    yield put(devicesActions.fetchDeviceTreeSuccess(deviceTree));
  }
  catch (err: any) {
    yield put(devicesActions.deletePanelFailed(err));

    toast.error(`Failed to ${isCreate ? 'create' : 'update'} panel`, err.response.status);
  }
}
function* deletePanel (action) {
  try {
    yield call(controlPanelsApi.deletePanel, action);

    yield put(devicesActions.deletePanelSuccess(action.panel));

    const deviceTree = yield call(devicesApi.getDeviceTree, action);

    yield put(devicesActions.fetchDeviceTreeSuccess(deviceTree));
  }
  catch (err: any) {
    yield put(devicesActions.updatePanelFailed(err));
    toast.error("Failed to delete panel", err.response.status);
  }
}

function* deleteChildDevice (action) {
  try {
    const response = yield call(devicesApi.deleteDevice, action, action.childId);
    if (response) {
      yield put(systemActions.registerMessageResponseHandler(response,
        [
          {
            action: devicesActions.deleteChildDeviceSuccess,
            params: [action.childId, action.deviceId],
          },
          {
            action: devicesActions.deleteDevice,
            params: [action.childId],
          }
        ],
        [
          {
            action: devicesActions.deleteChildDeviceFailed,
            params: [null],
            toast: 'Error'
          }
        ]
      ));
    } else {
      yield put(devicesActions.deleteChildDeviceSuccess(action.childId, action.deviceId));
      yield put(devicesActions.deleteDevice(action.childId));

    }
    yield put(devicesActions.fetchHiddenCircuits(action.deviceId));

  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Failed to delete device'), err.response.status);
    yield put(devicesActions.deleteChildDeviceFailed(Map(err)));
  }
}

function* fetchStatistics (action) {
  try {

    const statistics = yield call(devicesApi.getStatistics, action, action.deviceId);
    yield put(devicesActions.fetchStatisticsSuccess(statistics, action.deviceId));
  }
  catch (err: any) {
    yield put(devicesActions.fetchStatisticsFailed(Map(err), action.deviceId));
  }
}

function* fetchDashboardStats (action) {
  try {

    const stats = yield call(devicesApi.getDashboardStats, action);
    yield put(devicesActions.fetchDashboardStatsSuccess(stats));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDashboardStatsFailed(Map(err), action.deviceId));
    toast.error("Error retrieving dashboard statistics!", err.response.status);
  }
}

function* fetchSensorHistory (action) {
  try {
    const stats = yield call(devicesApi.getSensorHistory, action);
    yield put(devicesActions.fetchSensorHistorySuccess(stats, action.timeFrame, action.rollUp));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDashboardStatsFailed(Map(err)));
    toast.error("Error retrieving dashboard sensor history!", err.response.status);
  }
}

function* fetchExport (action) {
  try {
    const data = yield call(devicesApi.getExport, action, action.deviceId);
    yield put(devicesActions.fetchExportSuccess(data, action.deviceId));
  }
  catch (err: any) {
    yield put(devicesActions.fetchExportFailed(Map(err), action.deviceId));
    toast.error("Error retrieving device statistics!", err.response.status);
  }
}


function* fetchDeviceConfigFields (action) {
  try {
    const deviceConfigFields = yield call(devicesApi.getDeviceConfigFields, action, action.deviceId);
    yield put(devicesActions.fetchDeviceConfigFieldsSuccess(deviceConfigFields, action.deviceId));
  }
  catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else {
      yield put(devicesActions.fetchDeviceConfigFieldsFailed(Map(err)));
      toast.error("Error retrieving device configuration fields", err.response.status);
    }
  }
}

function* fetchDeviceConfigFieldsGeneric (action) {
  try {
    const deviceConfigFields = yield call(devicesApi.getDeviceConfigFieldsGeneric, action, action.models);
    yield put(devicesActions.fetchDeviceConfigFieldsGenericSuccess(deviceConfigFields, action.models));
  }
  catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else {
      yield put(devicesActions.fetchDeviceConfigFieldsFailed(Map(err)));
      toast.error("Error retrieving device configuration fields", err.response.status);
    }
  }
}

function* fetchDevicesProgrammingDiscrepancies (action) {
  try {
    const deviceDiffs = yield call(devicesApi.getDevicesProgrammingDiscrepancies, action);
    yield put(devicesActions.fetchDevicesProgrammingDiscrepanciesSuccess(deviceDiffs));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error fetching programming audit'), err.response.status);
    yield put(devicesActions.fetchDevicesProgrammingDiscrepanciesFailed(Map(err)));
  }
}

function* fetchDevice (action) {
  try {
    const device = yield call(devicesApi.getDevice, action);
    yield put(devicesActions.fetchDeviceSuccess(device));
  } catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else if (_.get(err, 'response.status') === 404) {
      yield put(devicesActions.fetchDeviceSuccess(Map({ id: action.deviceId })));
    } else {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device config'), err.response.status);
    }

    yield put(devicesActions.fetchDeviceFailed(Map(err)));
  }
}
function* fetchDeviceInfo (action) {
  try {
    const device = yield call(devicesApi.getDeviceInfo, action);

    yield put(devicesActions.fetchDeviceInfoSuccess(device));
  } catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else if (_.get(err, 'response.status') === 404) {
      yield put(devicesActions.fetchDeviceInfoSuccess(Map({ id: action.deviceId })));
    } else {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device info'), err.response.status);
    }

    yield put(devicesActions.fetchDeviceInfoFailed(Map(err), action.deviceId));
  }
}


function* fetchPanelByQrCode (action) {
  try {
    if (action.id) {
      const panelInfo = yield call(devicesApi.getPanelByQrCode, action);
      yield put(devicesActions.fetchPanelByQrCodeSuccess(action.id, panelInfo));
    }

  } catch (err: any) {
    yield put(devicesActions.fetchPanelByQrCodeFailed(Map(err)));
  }
}
function* fetchDeviceTree (action) {
  try {
    const deviceTree = yield call(devicesApi.getDeviceTree, action);

    yield put(devicesActions.fetchDeviceTreeSuccess(deviceTree));
  } catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching devices'), err.response.status);
    }

    yield put(devicesActions.fetchDeviceTreeFailed(Map(err)));
  }
}

function* fetchDeviceStatus (action) {
  try {
    yield call(devicesApi.fetchDeviceStatus, action);
  } catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else if (_.get(err, 'response.status') === 404) {
      yield put(devicesActions.fetchDeviceStatusFailed(Map(err), action.deviceId));
      return;
    } else {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device status'), err.response.status);
    }
    yield put(devicesActions.fetchDeviceStatusFailed(Map(err), action.deviceId));
  }
}

function* fetchDeviceAlarms (action) {
  try {
    yield call(devicesApi.fetchDeviceAlarms, action);
  } catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else if (_.get(err, 'response.status') === 404) {
      return;
    } else {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device alarms'), err.response.status);
    }
  }
}

function* fetchDeviceSettings (action) {
  try {
    yield call(devicesApi.fetchDeviceSettings, action);
  } catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else if (_.get(err, 'response.status') === 404) {
      yield put(devicesActions.fetchDeviceSettingsSuccess(action.deviceId));
    } else {
      toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device settings'), err.response.status);
    }
    yield put(devicesActions.fetchDeviceSettingsFailed(Map(err), action.deviceId));
  }
}

function* snapshotDeviceSettings (action) {
  try {
    const snapshotStatus = yield call(devicesApi.snapshotDeviceSettings, action);
    yield put(devicesActions.snapshotDeviceSettingsSuccess(action.deviceId, snapshotStatus));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error creating the snapshot of the device settings'), err.response.status);
    yield put(devicesActions.snapshotDeviceSettingsFailed(Map(err)));
  }
}

function* upgradeDevice (action) {
  try {
    const upgradeStatus = yield call(devicesApi.upgradeDevice, action);
    const messageIds = (Object.values(upgradeStatus.toJS()).map((x: any) => x.messageId) as any);
    for (const messageId of messageIds) {
      if (messageId) {
        yield put(systemActions.registerMessageResponseHandler(messageId,
          [
          ],
          [
            {
              action: devicesActions.upgradeDeviceFailed,
              params: [upgradeStatus],
              toast: 'Error'
            },

          ],
        ));
      }
    }

    yield put(devicesActions.upgradeDeviceSuccess(action.deviceId, upgradeStatus));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error migrating device settings'), err.response.status);
    yield put(devicesActions.upgradeDeviceFailed(Map(err)));
  }
}

function* fetchEnumeratedDeviceSettings (action) {
  try {
    const enumeratedDeviceSettings = yield call(devicesApi.fetchEnumeratedDeviceSettings, action);
    yield put(devicesActions.fetchEnumeratedDeviceSettingsSuccess(enumeratedDeviceSettings));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device settings'), err);
    yield put(devicesActions.fetchDeviceSettingsFailed(Map(err)));
  }
}
function* fetchHiddenCircuits (action) {
  try {
    const circuits = yield call(devicesApi.getHiddenCircuits, action);
    yield put(devicesActions.fetchHiddenCircuitsSuccess(action.deviceId, circuits));
  } catch (err: any) {
    yield put(devicesActions.fetchHiddenCircuitsFailed(Map(err)));
  }
}
function* unhideCircuit (action) {
  try {
    const circuit = yield call(devicesApi.unhideCircuit, action);

    // // added circuit to parent object circuit list
    yield put(circuitActions.addCircuitSuccess(circuit));
    // // add circuit to device list in redux
    yield put(devicesActions.loadDevicesFromCircuitsList([circuit]));
    yield put(devicesActions.setSettingsModalClose(circuit.get('parent_id')));
    yield put(devicesActions.fetchHiddenCircuits(circuit.get('parent_id')));
  } catch (err: any) {
    yield put(circuitActions.addCircuitFailed(Map(err)));
  }
}


function* updateDevice (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(action.deviceId));
    const device = yield call(devicesApi.updateDevice, action, action.deviceId, action.deviceConfig);
    yield put(devicesActions.updateDeviceSuccess(device));
    yield put(devicesActions.setSettingsModalClose(action.deviceId));
    yield put(devicesActions.fetchDevicesSettingsDiff(action.deviceId));
    const tag = device.get('tag');

    if (action.deviceConfig?.get('tag')) {
      if (device.get('type') !== 'circuit') {
        yield put(navigationActions.replaceHistory(`/devices/${encodeURIComponent(tag)}`));
      }
      else {
        if (device.get('parent_type') === 'commLoop') {
          yield put(navigationActions.replaceHistory(`/devices/_/${encodeURIComponent(tag)}`));
        } else {
          yield put(navigationActions.replaceHistory(`/devices/${encodeURIComponent(device.get('parent_tag'))}/${encodeURIComponent(tag)}`));
        }
      }
    }

  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating device config'), err.response.status);
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), action.deviceId));
    yield put(devicesActions.updateDeviceFailed(Map(err), action.deviceId));
  }
}

function* fetchDeviceSettingsDiff (action) {
  try {
    const settingsDiff = yield call(devicesApi.fetchDeviceSettingsDiff, action);
    yield put(devicesActions.fetchDevicesSettingsDiffSuccess(action.deviceId, settingsDiff));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error fetching device settings differences'), err.response.status);
  }
}

function* updateDeviceDesignedSettings (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(action.deviceId));
    const designedSettings = yield call(devicesApi.updateDeviceDesignedSettings, action);
    yield put(devicesActions.updateDeviceDesignedSettingsSuccess(action.deviceId, designedSettings));
    yield put(devicesActions.setSettingsModalClose(action.deviceId));
  }
  catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating device designed settings'), err.response.status);
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), action.deviceId));
    yield put(devicesActions.updateDeviceDesignedSettingsFailed(action.deviceId));
  }
}

function* updateDeviceSettings (action) {
  try {
    yield put(devicesActions.loadingSettingsModal(action.deviceId));
    const device = yield call(devicesApi.updateDeviceSettings, action, action.deviceId, action.deviceConfig?.get('settings')); // return message id back
    const updateSettingsHandlers = [
      [
        {
          action: devicesActions.setSettingsModalClose,
          params: [action.deviceId],
          toast: 'Successfully updated the settings.'
        },
        {
          action: devicesActions.fetchDevicesSettingsDiff,
          params: [action.deviceId],
        }
      ],
      [
        {
          action: devicesActions.stopLoadingSettingsModal,
          params: [null, action.deviceId]
        },
        {
          action: devicesActions.updateDeviceFailed,
          params: [null, action.deviceId],
          toast: 'Error'
        }
      ]
    ];
    if (action.fetchSettingsAfterUpdate) {
      updateSettingsHandlers[0].push({
        action: devicesActions.fetchDeviceSettings,
        params: [action.deviceId],
      });
    }
    yield put(systemActions.registerMessageResponseHandler(device.get('messageId'), updateSettingsHandlers[0], updateSettingsHandlers[1]));
  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error updating device config'), err.response.status);
    yield put(devicesActions.stopLoadingSettingsModal(Map(err), action.deviceId));
    yield put(devicesActions.updateDeviceFailed(Map(err), action.deviceId));
  }
}

function* updateDeviceSettingsFailed (action) {
  yield put(devicesActions.stopLoadingSettingsModal(Map(), action.deviceId));
  yield put(systemActions.removeMessageFromHandlers(action.messageId));
  toast.error(`${action.error}`);
}
function* dismissDeviceAlarms (action) {
  try {
    yield call(alarmsApi.dismissAlarms, action, action.note, action.controllerId, action.circuitId, action.alarmIds, action.expiredAt, action.subject, action.deviceId);
    const page = action.pageInfo;

    yield put(devicesActions.dismissDeviceAlarmsSuccess(action.alarmIds, page.deviceId));
    yield put(devicesActions.fetchDevicesActiveAlarms(action.alarmListFilters, Map({}), page.page, page.pageSize, page.filter, page.sort, page.deviceId));

  } catch (err: any) {
    toast.error(createUserFriendlyErrorMessage(err, 'Error resetting alarm!'), err.response.status);
    yield put(devicesActions.dismissDeviceAlarmsFailed(Map(err), action.pageInfo?.deviceId));
  }
}

function* channelHandler (channel) {
  try {
    const { autobahnEvent } = yield take(channel);
    switch (autobahnEvent.get('type')) {
      case 'data/device/fetch-device-settings-success':
        yield put(devicesActions.fetchDeviceSettingsSuccess(autobahnEvent.get('deviceId'), autobahnEvent.get('deviceSettings')));
        break;
      case 'data/device/update-device-settings-success':
        // in the rare case we get a response before we setup a device handler, save the response
        yield put(systemActions.addRecentFrogResponse(autobahnEvent.get('messageId'), autobahnEvent.set('type', 'system/frog-message-success')));
        yield put(devicesActions.updateDeviceSettingsSuccess(Map({
          id: autobahnEvent.get('deviceId'),
          settings: autobahnEvent.get('deviceSettings')
        })));
        yield put(devicesActions.setSettingsModalClose(autobahnEvent.get('deviceId')));
        yield put(devicesActions.upgradeADeviceSuccess(autobahnEvent.get('deviceId')));
        break;
      case 'data/device/fetch-device-status-success':
        yield put(devicesActions.fetchDeviceStatusSuccess(autobahnEvent.get('deviceId'), autobahnEvent.get('deviceStatus')));
        break;
      case 'data/device/replace-card-success':
        yield put(devicesActions.setSettingsModalClose(autobahnEvent.get('deviceId')));
        yield put(devicesActions.replaceCircuitCardSuccess(autobahnEvent.get('deviceId'), autobahnEvent.get('device')));
        toast.success('Successfully replaced card!');
        break;
      case 'data/device/sync-device-list-success':
        yield put(devicesActions.syncDevicesSuccess(autobahnEvent.getIn(['devices', 'deviceList'])));
        yield put(devicesActions.setSettingsModalClose(null));
        yield put(devicesActions.syncDevicesStageComplete(3, 3));
        toast.success('Successfully added controller.');
        break;
      case 'data/device/sync-device-list-success-part-one': {
        yield put(devicesActions.syncDevicesStageComplete(2, 3));
        yield put(devicesActions.syncDeviceInfoUpdate(autobahnEvent.getIn(['devices', 'deviceList']), null));
        yield put(systemActions.removeMessageFromHandlers(autobahnEvent.getIn(['messageId'])));
        // register any message ids
        const circuits = autobahnEvent.getIn(['devices', 'deviceList']);
        for (const x of circuits.valueSeq()) {
          yield put(systemActions.registerMessageResponseHandler(x.get('message_id'),
            [],
            [
              {
                action: devicesActions.syncDeviceInfoUpdate,
                params: [null, Map({ address: x.get('address'), tag: '-' }), 'warning'],
              },
              {
                action: devicesActions.syncDevicesStageComplete,
                params: [3, 3],
              }
            ],
            300000
          ));
        }

      } break;
      case 'data/device/sync-device-list-failed-part-one':
        yield put(devicesActions.syncDevicesStageComplete(3, 3));
        yield put(devicesActions.syncDeviceFailed());
        yield put(devicesActions.addDeviceFailedCleanUp(autobahnEvent.getIn(['deviceId'])));
        toast.error('Unable to added controller!');
        break;
      case 'data/device/sync-device-list-success-part-two': {
        yield put(devicesActions.syncDevicesStageComplete(3, 3));
        const tags = autobahnEvent.getIn(['devices', 'tag']);
        yield put(devicesActions.syncDeviceInfoUpdate(null, tags, 'success'));
        yield put(systemActions.removeMessageFromHandlers(autobahnEvent.getIn(['devices', 'messageId'])));
        break;
      }
      case 'data/device/sync-device-list-failed-part-two':
        yield put(devicesActions.syncDevicesStageComplete(3, 3, autobahnEvent.getIn(['deviceId'])));
        yield put(devicesActions.syncDeviceInfoUpdate(null, autobahnEvent.getIn(['devices', 'tag']), 'error'));
        yield put(systemActions.removeMessageFromHandlers(autobahnEvent.getIn(['devices', 'messageId'])));
        break;
      case 'data/device/batch-status-update':
        yield put(batchScheduleActions.fetchBatchStatusSuccess(autobahnEvent.getIn(['data', 'batchId']), Map({
          status: autobahnEvent.getIn(['data', 'status']),
          deviceInError: autobahnEvent.getIn(['data', 'deviceInError']),
          completed_at: autobahnEvent.getIn(['data', 'completed_at']),
          device_id: autobahnEvent.getIn(['data', 'device_id'])
        })));
        break;
      case  'data/device/device-settings-snapshot-success':
        yield put(devicesActions.snapshotSettingsSuccess(autobahnEvent.get('deviceId')));
        break;
      case 'data/device/device-settings-snapshot-warning':
        yield put(devicesActions.snapshotSettingsWarning(autobahnEvent.get('deviceId')));
        break;
      case 'data/device/device-settings-snapshot-failed':
        yield put(devicesActions.snapshotSettingsFailed(autobahnEvent.get('deviceId')));
        break;
    }

  } catch (err: any) {
    console.error(err); // eslint-disable-line no-console
  }
}

function* channelConsumer (channel) {
  while (true) {
    yield channelHandler(channel);
  }
}
function* uploadDeviceFile (action) {
  try {
    const ext = getFileExtension(action.importFile.name).toLowerCase();
    const importData = yield call(devicesApi.uploadFile, action, action.importFile, action.deviceId);
    yield put(devicesActions.uploadDeviceFileSuccess(importData, action.deviceId, ext));
    yield put(devicesActions.setSettingsModalClose(action.deviceId));
  } catch (err: any) {
    toast.error(`Failed to add ${action.importFile.name}`, err.response.status);
    yield put(devicesActions.uploadDeviceFileFailed(action.importFile, action.deviceId));

  }
}

function* updateDeviceFileContents (action) {
  try {
    const importData = yield call(devicesApi.updateDeviceFileContents, action, action.importFile, action.deviceId, action.uploadId, action.version);
    yield put(devicesActions.setSettingsModalClose(action.deviceId));
    yield put(devicesActions.updateDeviceFileContentsSuccess(action.fileId, action.deviceId, importData));
  } catch (err: any) {
    toast.error(`Failed to update ${action.importFile.name}: ${err.response.data.message}`, err.response.status);
    yield put(devicesActions.updateDeviceFileContentsFailed(action.deviceId));
  }
}

function* removeDeviceFile (action) {
  try {
    yield call(devicesApi.deleteFile, action, action.deviceId);
    yield put(devicesActions.removeDeviceFileSuccess(action.fileId, action.deviceId));

  } catch (err: any) {
    toast.error(`Failed to remove file ${action.fileName}`, err.response.status);
    yield put(devicesActions.removeDeviceFileFailed(
      action.fileName, action.created_at, action.deviceId));
  }
}
// get all file info
function* fetchDeviceFiles (action) {
  try {
    const data = yield call(devicesApi.getFiles, action, action.deviceId);
    yield put(devicesActions.fetchDeviceFilesSuccess(data, action.deviceId));
  }
  catch (err: any) {
    yield put(devicesActions.fetchDeviceFilesFailed(action.deviceId));
    toast.error("Error retrieving device file!", err.response.status);
  }
}
// get a file
function* fetchDeviceFile (action) {
  try {
    const data = yield call(devicesApi.getFile, action, action.deviceId);
    // pdf open in a new tab, others download file right away
    if (action.fileName.includes(".pdf")) {
      const file = new Blob([(data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      const blob = new Blob([data]);
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', action.fileName);
      link.click();
    }

  }
  catch (err: any) {
    toast.error("Error retrieving device file!", err.response.status);
  }
}

function* updateDeviceFilesDescription (action) {
  try {
    yield call(devicesApi.updateFileDescription, action, action.deviceId);
    yield put(devicesActions.updateDeviceFilesDescriptionSuccess(action.fileId, action.description, action.deviceId));
  }
  catch (err: any) {
    yield put(devicesActions.updateDeviceFilesDescriptionFailed(action.deviceId));
    toast.error("Error updating files description!", err.response.status);
  }
}
function* updateDeviceFilesSubType (action) {
  try {
    const file = yield call(devicesApi.updateFileSubType, action, action.deviceId);
    yield put(devicesActions.updateDeviceFilesSubTypeSuccess(action.fileId, action.subType, action.deviceId));
    yield put(checkSheetActions.updateCheckSheetTypeSuccess( action.subType, file.get('checkSheetId')));

  }
  catch (err: any) {
    yield put(devicesActions.updateDeviceFilesSubTypeFailed(action.deviceId));
    toast.error("Error updating files sub type!", err.response.status);
  }
}


function* dispatchDeviceAction (action) {
  try {
    yield call(devicesApi.dispatchDeviceAction, action);
    toast.success(action.deviceActionObj.get('toast', 'Sent request to device'));
  } catch (err: any) {
    yield put(devicesActions.dispatchDeviceActionFailed(Map(err)));
    toast.error(createUserFriendlyErrorMessage(err, `Error requesting ${action.deviceActionObj.get('friendlyName', 'action')} from device`), err.response.status);
  }
}

function* fetchDeviceActions (action) {
  try {
    const deviceActions = yield call(devicesApi.fetchDeviceActions, action);
    yield put(devicesActions.fetchDeviceActionsSuccess(deviceActions, action.deviceId));
  }
  catch (err: any) {
    if (err.toString() === 'Error: Request aborted') {
      return;
    }
    else if (err.toString() === 'Error: Network Error') {
      toast.error(createUserFriendlyErrorMessage(err, 'Unable to connect with the SmartTrace servers at this time, please try again later.'), err.response.status);
    }
    else {
      yield put(devicesActions.fetchDeviceActionsFailed(Map(err)));
      toast.error("Error retrieving device actions", err.response.status);
    }
  }
}


function* generateTestingReport (action) {
  try {
    const response = yield call(devicesApi.generateTestingReport, action);
    yield put(devicesActions.generateTestingReportSuccess(action.reportType, response ));
  }
  catch (err: any) {
    toast.error("Error generating testing report");
    yield put(devicesActions.generateTestingReportFailed(action.reportType));
  }
}
function* generateTestingReportPdf (action) {
  try {
    const response2 = yield call(devicesApi.createCsvReport, action);
    yield put((devicesActions.generateTestingReportPdfSuccess()));
    const url = window.URL.createObjectURL(new Blob([response2]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'generated.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  catch (err: any) {
    toast.error("Error generating testing report PDF");
    yield put((devicesActions.generateTestingReportPdfFailed()));

  }
}
function* fetchDeviceCheckSheets (action) {
  try {
    const response = yield call(checkSheetApi.fetchDeviceCheckSheets, action);

    yield put(devicesActions.fetchDeviceCheckSheetsSuccess(action.deviceId, action.checkSheetType, response ));
    yield put(checkSheetActions.fetchCheckSheetsSuccess(action.checkSheetType, response.get('data')));
  }
  catch (err: any) {
    toast.error("Error fetching device check sheets!");
    yield put(devicesActions.fetchDeviceCheckSheetsFailed(action.deviceId, action.checkSheetType));
  }
}
function* deleteCommLoop (action) {
  try {
    yield call(devicesApi.deleteDevice, action, action.deviceId);
    yield put(navigationActions.pushHistory(`/devices/comm-loops`));

    yield put(devicesActions.deleteCommLoopSuccess(action.deviceId));
  }
  catch (err: any) {
    toast.error("Error deleting Comm Loop!");
  }
}

export default devicesRoot;
