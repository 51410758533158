import './CommLoopsList.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DataTable from 'src/component/UI/DataTable';

import { fetchCommLoops } from 'src/module/device/action';
import { getCommLoops, getCommLoopsPageInfo } from 'src/module/device/selector';

import { formatNumberByCommas } from 'src/utils/utils';
import DeviceLink from 'src/component/UI/DeviceLink';
import CircleStatusIndicator from './CircleStatusIndicator';
import variables from 'src/style/variable/variables.module.scss';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';

export default function CommLoopsList () {

  const pageInfo = useSelector(getCommLoopsPageInfo);

  const commLoops = useSelector(getCommLoops);

  const [page, setPage] = useState(pageInfo.get('page', 0));
  const [pageSize, setPageSize] = useState(pageInfo.get('pageSize', 10));
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);

  const dispatch: any = useDispatch();

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    setSort(_sort);
  };

  /* Calculate saturation as a percentage */
  const calculateSaturation = (totalMessages, totalSkipped) => {
    if (!totalMessages) {
      return null;
    }
    if (!totalSkipped) {
      return 0;
    }

    return Math.round(totalSkipped / totalMessages * 100);
  };

  const getStaturationStatusIndicator = (saturation) => {
    let indicatorColor = undefined;
    if (saturation == null) {
      indicatorColor = variables.greyLight;
    }
    else if (saturation >= (process.env.REACT_APP_HIGH_SATURATION ?? 40)) {
      indicatorColor = variables.error;
    }
    else if (saturation >= (process.env.REACT_APP_LOW_SATURATION ?? 20)) {
      indicatorColor = variables.warning;
    }
    else {
      indicatorColor = variables.success;
    }
    return (
      <CircleStatusIndicator color={indicatorColor} />
    );

  };

  useEffect(() => {
    const paginationDetails = { page, pageSize, filter, sort };
    dispatch(fetchCommLoops(paginationDetails));
  }, [page, pageSize, filter, sort]);

  const dataColumns = [
    {
      id: 'tag',
      Header: 'Comm Loop Tag',
      accessor: (row: any) => row.get('tag'),
      sortable: true,
      Cell: (row: any) => {
        return <DeviceLink
          label={row.cell.row.original.get('tag')}
          controllerTag={row.cell.row.original.get('tag')}
        />;
      }
    },
    {
      id: 'channelSaturation',
      Header: (<Tooltip title={
        <div>
          <table>
            <tbody>
              <tr>
                <td>GREY</td>
                <td>Not enough data</td>
              </tr>
              <tr>
                <td>GREEN</td>
                <td>Normal message throughput</td>
              </tr>
              <tr>
                <td>YELLOW</td>
                <td>Moderate message backlog</td>
              </tr>
              <tr>
                <td>RED</td>
                <td>Heavy message backlog</td>
              </tr>
            </tbody>
          </table>
        </div>

      }><div style={{ display: 'flex' }}>Status<InfoIcon className="alert-icon" width={16} height={16} style={{ marginLeft: '5px' }} /></div></Tooltip>),
      accessor: (row: any) => row.get('status'),
      sortable: true,
      Cell: (row: any) => {
        return row.cell.value ?
          getStaturationStatusIndicator(calculateSaturation(
            row.cell.value.get('totalSent'),
            row.cell.value.get('totalSkipped')
          )) :
          getStaturationStatusIndicator(null);
      }
    },
    {
      id: 'ip_address',
      Header: 'Address',
      accessor: (row: any) => row.get('ip_address'),
      sortable: true,
      Cell: (row: any) => { return row.cell.value || 'N/A'; }
    },
    {
      id: 'port',
      Header: 'Port',
      accessor: (row: any) => row.get('port'),
      sortable: true,
      Cell: (row: any) => { return row.cell.value || 'N/A'; }
    },
    {
      id: 'totalSent',
      Header: 'Total Messages Sent',
      accessor: (row: any) => row.getIn(['status', 'totalSent']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'totalDropped',
      Header: 'Dropped Messages',
      accessor: (row: any) => row.getIn(['status', 'totalDropped']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'modbusExceptions',
      Header: 'Modbus Errors',
      accessor: (row: any) => row.getIn(['status', 'modbusExceptions']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'commFailRatio',
      Header: 'Comm Fail Ratio',
      accessor: (row: any) => {
        const errors = row.getIn(['status', 'modbusExceptions']);
        const total = row.getIn(['status', 'totalSent']);
        let percentage: any = 0;
        if (total > 0 && errors > 0) {
          percentage = (errors * 100) / total;
          return Number.isInteger(percentage) ? `${parseInt(percentage)}%` : `${percentage.toFixed(2)}%`;
        }
        return `0%`;
      },
      disableSortBy: true
    },
    {
      id: 'avgMessageTime',
      Header: 'Average Message Time',
      accessor: (row: any) => row.getIn(['status', 'avgMessageTime']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? `${formatNumberByCommas(parseInt(row.cell.value))} ms` : `0 ms`; }
    }
  ];


  return (
    <div className='comm-loops-list' data-testid='comm-loops-list'>
      <h2>Comm Loops</h2>
      <DataTable
        filterable
        filterLabel='Search Comm Loops'
        columns={dataColumns}
        data={commLoops}
        pagination
        numPages={pageInfo.get('totalPages', 0)}
        totalRecords={pageInfo.get('total', 0)}
        loading={pageInfo.get('loading', false)}
        fetchPage={fetchPage}
        columnWidths={['15%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']}
        tableId='commLoopsTable'
      />
    </div>
  );
}

CommLoopsList.propTypes = {
};

CommLoopsList.defaultProps = {
};
